import { useEffect, useState } from "react";
import { useUserContext } from "../../../contexts/user.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import settings from "../../../../utils/settings";
import { useMessageContext } from "../../../contexts/message.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const { userId } = useParams();
  const [user, setUser] = useState();
  const [adds, setAdds] = useState([]);
  const navigate = useNavigate();
  const { showSuccess } = useMessageContext();

  const refresh = async () => {
    try {
      const user = await apiServerClient.admin.user.getById(userId);
      const adds = await apiServerClient.admin.add.getByUserId(userId);

      setAdds(adds);
      setUser(user);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleAddClicked = (add) => {
    navigate(`./${add._id}/anuncio`);
  };

  const handleCopyVerifyLinkClicked = () => {
    const url = `https://${settings.company.www}/miembros/verificar/${user._id}`;
    navigator.clipboard.writeText(url);
    showSuccess("Enlace copiado");
  };

  const handleCopyPasswordClicked = () => {
    navigator.clipboard.writeText(user.password);
    showSuccess("Contraseña copiado");
  };

  if (!user) return null;

  return (
    <div className="container">
      <div className="header1">Usuario</div>
      <div className="form-group mb-2">
        <label className="form-label">Nombre</label>
        <div>{user.name}</div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">Correo</label>
        <div>{user.email}</div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Correo verificado</label>
        <div>
          {user.isVerified ? "Si" : "No"}{" "}
          {!user.isVerified && (
            <span onClick={handleCopyVerifyLinkClicked} className="clickable">
              <FontAwesomeIcon icon={faCopy} />
            </span>
          )}
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Contraseña</label>
        <div>
          {user.password.replace(/./g, "*")}{" "}
          <span onClick={handleCopyPasswordClicked} className="clickable">
            <FontAwesomeIcon icon={faCopy} />
          </span>
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Anuncios</label>
        <table className="table table-striped table-clickable">
          <thead>
            <tr>
              <th>Id</th>
            </tr>
          </thead>
          <tbody>
            {adds.map((a) => (
              <tr key={a._id} onClick={() => handleAddClicked(a)}>
                <td>{a.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Page;
