import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import hexRgb from "hex-rgb";

import mandameinbox from "../../../../images/Logo Mandame Inbox 01.PNG";
import comprar from "../../../../images/Publicar Ahora2.png";
import paymentInstructions from "../../../../images/Boleta de Pago.PNG";

import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

// https://www.npmjs.com/package/hex-rgb
const colourIsLight = (r, g, b) => {
  // Counting the perceptive luminance
  // human eye favors green color...
  //var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  var a = 1 - (0.333 * r + 0.333 * g + 0.333 * b) / 255;
  return a < 0.5;
};

const convertToLines = (context, note, maxWidth) => {
  var result = [];
  var lines = note.split("\n");

  for (var l = 0; l < lines.length; l++) {
    var line = "";
    var words = lines[l].split(" ");
    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        result.push(line);
        line = words[n] + " ";
      } else {
        line = testLine;
      }
    }
    result.push(line);
  }

  while (result.length > 0) {
    if (result[0].trim() === "") {
      result.shift();
    } else break;
  }

  while (result.length > 0) {
    if (result[result.length - 1].trim() === "") {
      result.pop();
    } else break;
  }

  return result;
};

const parseNote = (note) => {
  return note.substring(0, 250);
  // var lines = note.split("\n");
  // const lineMaxLength = 100;

  // if (lines.length > 6) lines.length = 6;
  // for (var i = 0; i < lines.length; i++) {
  //   if (lines[i].length > lineMaxLength)
  //     lines[i] = lines[i].substring(0, lineMaxLength);
  // }

  // return lines.join("\n");
};

const Page = () => {
  const canvasRef = useRef();
  const { apiServerClient } = useUserContext();
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const navigate = useNavigate();
  const { addId } = useParams();
  const [dummy, setDummy] = useState(1);
  const imageRef = useRef(new Image());
  const [add, setAdd] = useState();
  const [promotions, setPromotions] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const refresh = async () => {
    try {
      const add = await apiServerClient.customer.add.getById(addId);

      const promotions = await apiServerClient.customer.promotion.getByAddId(
        addId
      );

      setPromotions(promotions);

      const version = add.reviewVersion || add.currentVersion;

      window.onresize = () => {
        updatePreview(version.note, version.backgroundColor);
      };

      imageRef.current.onload = () => {
        imageRef.current.loaded = true;
        updatePreview(version.note, version.backgroundColor);
      };

      imageRef.current.src = version.imageFile.url;
      imageRef.current.loaded = false;

      setAdd(add);
    } catch (ex) {
      handleException(ex);
    }
  };

  const updatePreview = (note, backgroundColor) => {
    if (!imageRef.current.loaded) return;
    const ctx = canvasRef.current.getContext("2d");
    const imageAspectRatio = imageRef.current
      ? imageRef.current.height / imageRef.current.width
      : 1.5;
    const imageCanvasRatio = imageAspectRatio > 2 ? 2 : imageAspectRatio;
    const imageCanvasWidth = canvasRef.current.clientWidth;
    const imageCanvasHeight = imageCanvasRatio * imageCanvasWidth;

    const text = note || "";
    ctx.font = "10px Arial";
    const lines = convertToLines(ctx, text, imageCanvasWidth);
    const noteHeight = lines.length ? lines.length * 10 + 10 : 0;
    canvasRef.current.style.height = `${imageCanvasHeight + noteHeight}px`;

    canvasRef.current.width = imageCanvasWidth;
    canvasRef.current.height = imageCanvasHeight + noteHeight;

    ctx.clearRect(0, 0, imageCanvasWidth, imageCanvasHeight + noteHeight);

    // draw background
    ctx.fillStyle = backgroundColor;
    if (noteHeight) {
      ctx.fillRect(0, imageCanvasHeight, imageCanvasWidth, noteHeight);
    }

    //draw image
    if (imageRef.current?.width) {
      ctx.drawImage(
        imageRef.current,
        0,
        0,
        imageCanvasWidth,
        imageCanvasHeight
      );
    }

    // draw text
    const backgroundColorRGB = hexRgb(backgroundColor);
    const isBackgroundColorLight = colourIsLight(
      backgroundColorRGB.red,
      backgroundColorRGB.green,
      backgroundColorRGB.blue
    );

    ctx.fillStyle = isBackgroundColorLight ? "black" : "white";
    ctx.font = "10px Arial";
    ctx.textAlign = "left";
    for (var l = 0; l < lines.length; l++) {
      ctx.fillText(
        lines[l],
        0, //imageCanvasWidth / 2,
        imageCanvasHeight + (l + 1) * 10
      );
    }

    // reload if image aspect ratio is invalid
    setDummy(dummy + 1);
  };

  useEffect(() => {
    refresh();
  }, []);

  const onCreateClicked = async (promotion) => {
    try {
      setIsWorking(true);
      await apiServerClient.customer.add.createOrder(addId, promotion._id);
      showSuccess("Orden creado");
      navigate("./../..");
    } catch (ex) {
      handleException(ex);
      setIsWorking(false);
    }
  };

  return (
    <div className="container">
      <div>
        <img
          src={mandameinbox}
          alt=""
          style={{ maxHeight: 150, marginBottom: 30 }}
        />
      </div>
      <div className="row">
        <div className="col-sm">
          <label className="form-label">Elije tiempo de publicación:</label>
          {promotions.map((p, index) => {
            return (
              <div
                key={p._id}
                className={`row ${index > 0 ? "mt-4" : ""}`}
                style={{
                  borderRadius: 5,
                  border: "1px solid #ced4da",
                }}
              >
                <div
                  className="col-6"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 20, fontWeight: "bold" }}>
                    {p.description}
                  </div>
                  <div style={{ fontSize: 26, fontWeight: "bold" }}>
                    ${p.price} pesos
                  </div>
                </div>
                <div className="col-6 text-end">
                  <button
                    className="mybtn btn"
                    onClick={() => onCreateClicked(p)}
                    disabled={isWorking}
                    style={{ marginTop: 30 }}
                  >
                    {p.price > 0 ? (
                      <img
                        src={paymentInstructions}
                        alt=""
                        className="img-fluid btnImg80"
                      />
                    ) : (
                      <img
                        src={comprar}
                        alt=""
                        className="img-fluid btnImg80"
                      />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="col-sm">
          <div>
            <label className="form-label">Previsualizar:</label>
            <div className="col">
              <canvas
                width={300}
                height={300}
                ref={canvasRef}
                style={{
                  width: "100%",
                  height: 300,
                  border: "1px solid #ced4da",
                  borderRadius: 5,
                }}
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        {step.render()}
        <div>
          {step.hasPrevious() && (
            <button
              onClick={step.gotoPrevious}
              disabled={!step.isFormOk()}
              className="mybtn btn"
              style={{ marginTop: 30, maxWidth: "50%" }}
            >
              <img src={prev} alt="" className="img-fluid btnImg80" />
            </button>
          )}
          {step.hasNext() && (
            <button
              onClick={step.gotoNext}
              disabled={!step.isFormOk()}
              className="mybtn btn"
              style={{ marginTop: 30, maxWidth: "50%" }}
            >
              <img src={next} alt="" className="img-fluid btnImg80" />
            </button>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default Page;
