import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const categories = await apiServerClient.admin.category.getAll();
      categories.sort((a, b) => (a.number || 0) - (b.number || 0));
      setCategories(categories);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const onCategoryClicked = (category) => {
    navigate(`./${category._id}`);
  };

  return (
    <div className="container">
      <div className="header1">Categorias</div>
      <div>
        <table className="table table-striped table-clickable">
          <thead>
            <tr>
              <th>Número</th>
              <th>Nombre</th>
              <th>Encabezamiento</th>
              <th>Indice</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((c) => {
              return (
                <tr key={c._id} onClick={() => onCategoryClicked(c)}>
                  <td>{c.number}</td>
                  <td>{c.name}</td>
                  <td>
                    <img
                      src={c.headerFile?.url}
                      alt=""
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    />
                  </td>
                  <td>
                    <div>x: {c.bounds?.x}</div>
                    <div>y: {c.bounds?.y}</div>
                    <div>ancho: {c.bounds?.w}</div>
                    <div>altura: {c.bounds?.h}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Page;
