class ApiServerClient {
  #token = null;

  #handleResponse = async (response) => {
    if (response.ok) {
      const response2 = await response.json();
      return response2;
    } else if (response.status === 500) {
      const response2 = await response.json();
      throw new Error(response2.error);
    } else {
      const response2 = await response.statusText;
      throw new Error(response2);
    }
  };

  #post = async (url, authenticate, data) => {
    console.log(url);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (authenticate) {
      options.headers.Authorization = "Bearer " + this.#token;
    }

    if (data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(`/api/v1${url}`, options);

    const result = await this.#handleResponse(response);
    console.log("result", result);

    return result;
  };

  #postFormData = async (url, authenticate, formData) => {
    const options = {
      method: "POST",
      body: formData,
    };

    if (authenticate) {
      options.headers = {
        Authorization: "Bearer " + this.#token,
      };
    }

    const response = await fetch(`/api/v1${url}`, options);

    const data = await this.#handleResponse(response);

    return data;
  };

  visitor = {
    add: {
      getByArea: async (area) => {
        const adds = await this.#post("/add/getByAreaAsVisitor", false, {
          area,
        });

        return adds;
      },
    },
    area: {
      getAll: async () => {
        const areas = await this.#post("/area/getAllAsVisitor", false);

        return areas;
      },
      getUsed: async () => {
        const areas = await this.#post("/area/getUsedAsVisitor", false);

        return areas;
      },
      getByName: async (name) => {
        const area = await this.#post("/area/getByNameAsVisitor", false, {
          name,
        });

        return area;
      },
    },
    help: {
      sendHelpAboutDesignEmail: async (name, email, whatsapp) => {
        const data = await this.#post(
          "/help/sendHelpAboutDesignEmailAsVisitor",
          false,
          {
            name,
            email,
            whatsapp,
          }
        );

        return data;
      },
    },
    category: {
      getAll: async () => {
        const categories = await this.#post("/category/getAllAsVisitor", false);

        return categories;
      },
    },
    settings: {
      getAll: async () => {
        const settings = await this.#post("/settings/getAllAsVisitor", false);

        return settings;
      },
    },
    promotion: {
      getAll: async () => {
        const promotions = await this.#post("/promotion/getAllAsVisitor", true);

        return promotions;
      },
    },
  };

  customer = {
    area: {
      getAll: async () => {
        const areas = await this.#post("/area/getAllAsCustomer", true);

        return areas;
      },
    },
    category: {
      getAll: async () => {
        const categories = await this.#post("/category/getAllAsCustomer", true);

        return categories;
      },
    },
    add: {
      getById: async (addId) => {
        const add = await this.#post("/add/getByIdAsCustomer", true, { addId });

        return add;
      },
      create: async (
        note,
        areaIds,
        imageFile,
        categoryId,
        backgroundColor,
        promotionId
      ) => {
        var formData = new FormData();
        formData.append(
          "data",
          JSON.stringify({
            note,
            areaIds,
            categoryId,
            backgroundColor,
            promotionId,
          })
        );
        formData.append("image", imageFile);

        const add = await this.#postFormData(
          "/add/createAsCustomer",
          true,
          formData
        );

        return add;
      },
      update: async (
        addId,
        note,
        areaIds,
        imageFile,
        categoryId,
        backgroundColor
      ) => {
        var formData = new FormData();
        const data = {
          addId,
          note,
          areaIds,
          categoryId,
          backgroundColor,
        };

        if (imageFile) {
          formData.append("image", imageFile);
        }
        formData.append("data", JSON.stringify(data));

        await this.#postFormData("/add/updateAsCustomer", true, formData);
      },
      delete: async (addId) => {
        await this.#post("/add/deleteAsCustomer", true, { addId });
      },
      sendPaymentInstructions: async (addId) => {
        await this.#post("/add/sendPaymentInstructionsAsCustomer", true, {
          addId,
        });
      },
      createOrder: async (addId, promotionId) => {
        await this.#post("/add/createOrderAsCustomer", true, {
          addId,
          promotionId,
        });
      },
      getAll: async () => {
        const adds = await this.#post("/add/getAllAsCustomer", true);

        return adds;
      },
    },
    promotion: {
      getByAddId: async (addId) => {
        const promotions = await this.#post(
          "/promotion/getByAddIdAsCustomer",
          true,
          {
            addId,
          }
        );

        return promotions;
      },
    },
  };

  admin = {
    add: {
      getAll: async () => {
        const adds = await this.#post("/add/getAllAsAdmin", true);

        return adds;
      },
      getByUserId: async (userId) => {
        const adds = await this.#post("/add/getByUserIdAsAdmin", true, {
          userId,
        });

        return adds;
      },
      getById: async (addId) => {
        const add = await this.#post("/add/getByIdAsAdmin", true, { addId });

        return add;
      },
      addTime: async (addId, days) => {
        await this.#post("/add/addTimeAsAdmin", true, { addId, days });
      },
      getReviews: async () => {
        const adds = await this.#post("/add/getReviewsAsAdmin", true);

        return adds;
      },
      delete: async (addId) => {
        await this.#post("/add/deleteAsAdmin", true, { addId });
      },
      accept: async (addId) => {
        await this.#post("/add/acceptAsAdmin", true, { addId });
      },
      reject: async (addId, reason) => {
        await this.#post("/add/rejectAsAdmin", true, { addId, reason });
      },
      registerPayment: async (addId, amount, reference) => {
        await this.#post("/add/registerPaymentAsAdmin", true, {
          addId,
          reference,
          amount,
        });
      },
    },
    addEvents: {
      getByAddId: async (addId) => {
        const events = await this.#post("/addEvent/getByAddIdAsAdmin", true, {
          addId,
        });

        return events;
      },
    },
    area: {
      getAll: async () => {
        const areas = await this.#post("/area/getAllAsAdmin", false);

        return areas;
      },
      getById: async (areaId) => {
        const area = await this.#post("/area/getByIdAsAdmin", true, { areaId });

        return area;
      },
      update: async (areaId, file1, file2) => {
        const data = new FormData();
        data.append("data", JSON.stringify({ areaId }));

        data.append("file1", file1);
        data.append("file2", file2);
        await this.#postFormData("/area/updateAsAdmin", true, data);
      },
    },
    files: {
      getAll: async () => {
        const files = await this.#post("/files/getAllAsAdmin", true);
        return files;
      },
      insert: async (file) => {
        var formData = new FormData();
        formData.append("file", file);

        const file2 = await this.#postFormData(
          "/files/insertAsAdmin",
          true,
          formData
        );

        return file2;
      },
      delete: async (fileName) => {
        await this.#post("/files/deleteAsAdmin", true, {
          fileName,
        });
      },
      deleteAll: async () => {
        await this.#post("/files/deleteAllAsAdmin", true);
      },
    },
    category: {
      getAll: async () => {
        const categories = await this.#post("/category/getAllAsAdmin", true);

        return categories;
      },
      getById: async (categoryId) => {
        const category = await this.#post("/category/getByIdAsAdmin", true, {
          categoryId,
        });

        return category;
      },
      update: async (categoryId, headerFile, x, y, w, h, number) => {
        const data = new FormData();
        data.append("data", JSON.stringify({ categoryId, x, y, w, h, number }));
        data.append("file", headerFile);
        await this.#postFormData("/category/updateAsAdmin", true, data);
      },
    },
    magazine: {
      generate: async (type) => {
        await this.#post("/magazine/generateAsAdmin", true, { type });
      },
      getAll: async () => {
        return await this.#post("/magazine/getAllAsAdmin", true);
      },
      getNewest: async (amount) => {
        return await this.#post("/magazine/getNewestAsAdmin", true, { amount });
      },
    },
    settings: {
      getAll: async () => {
        const settings = await this.#post("/settings/getAllAsAdmin", false);

        return settings;
      },
      update: async (indexFile, noAddsFile, oneMagazineCoverFile) => {
        const data = new FormData();

        data.append("indexFile", indexFile);
        data.append("noAddsFile", noAddsFile);
        data.append("oneMagazineCoverFile", oneMagazineCoverFile);

        await this.#postFormData("/settings/updateAsAdmin", true, data);
      },
    },
    promotion: {
      getAll: async () => {
        const promotions = await this.#post("/promotion/getAllAsAdmin", true);

        return promotions;
      },
      getById: async (promotionId) => {
        const promotion = await this.#post("/promotion/getByIdAsAdmin", true, {
          promotionId,
        });

        return promotion;
      },
      getTypes: async () => {
        const promotions = await this.#post("/promotion/getTypesAsAdmin", true);

        return promotions;
      },
      update: async (
        promotionId,
        description,
        startDate,
        endDate,
        months,
        price,
        maxPromotionsPerAdd,
        maxPromotionsPerUser,
        hidden
      ) => {
        await this.#post("/promotion/updateAsAdmin", true, {
          promotionId,
          description,
          startDate,
          endDate,
          months,
          price,
          maxPromotionsPerAdd,
          maxPromotionsPerUser,
          hidden,
        });
      },
      create: async (
        description,
        startDate,
        endDate,
        months,
        price,
        maxPromotionsPerAdd,
        maxPromotionsPerUser,
        hidden
      ) => {
        await this.#post("/promotion/createAsAdmin", true, {
          description,
          startDate,
          endDate,
          months,
          price,
          maxPromotionsPerAdd,
          maxPromotionsPerUser,
          hidden,
        });
      },
      delete: async (promotionId) => {
        await this.#post("/promotion/deleteAsAdmin", true, { promotionId });
      },
    },
    user: {
      getByInterval: async (startDate, endDate) => {
        const users = await this.#post("/user/getByIntervalAsAdmin", true, {
          startDate,
          endDate,
        });

        return users;
      },
      getByNameOrEmailOrAddId: async (nameOrEmailOrAddId) => {
        const users = await this.#post(
          "/user/getByNameOrEmailOrAddIdAsAdmin",
          true,
          {
            nameOrEmailOrAddId,
          }
        );

        return users;
      },
      getByNameOrEmail: async (nameOrEmail) => {
        const users = await this.#post("/user/getByNameOrEmailAsAdmin", true, {
          nameOrEmail,
        });

        return users;
      },
      getById: async (userId) => {
        const user = await this.#post("/user/getByIdAsAdmin", true, {
          userId,
        });

        return user;
      },
    },
  };

  user = {
    signup: async (name, email, password) => {
      const data = await this.#post("/user/signup", false, {
        name,
        email,
        password,
      });

      return data;
    },
    signinByCredentials: async (email, password) => {
      const auth = await this.#post("/user/signinByCredentials", false, {
        email,
        password,
      });

      this.#token = auth.token;

      return auth;
    },
    signinByToken: async (token) => {
      const auth = await this.#post("/user/signinByToken", false, { token });

      this.#token = auth.token;

      return auth;
    },
    signout: async () => {
      await this.#post("/user/signout", true);
    },
    sendLostPassword: async (email) => {
      const auth = await this.#post("/user/sendLostPassword", true, { email });

      return auth;
    },
    update: async (name, password) => {
      await this.#post("/user/update", true, {
        name,
        password,
      });
    },
    verifyemail: async (userId, password) => {
      const auth = await this.#post("/user/verifyemail", false, {
        userId,
        password,
      });

      this.#token = auth.token;

      return auth;
    },
  };
}

export default ApiServerClient;
