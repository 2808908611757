import { useEffect, useState } from "react";
import { useUserContext } from "../../../contexts/user.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useNavigate, useParams } from "react-router-dom";

import AddControl from "../../../controls/add.control";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const { addId } = useParams();
  const [add, setAdd] = useState();
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const add = await apiServerClient.admin.add.getById(addId);

      setAdd(add);
    } catch (ex) {
      handleException(ex);
    }
  };

  const handleAddClicked = () => {
    navigate("../anuncio");
  };

  const handleEventsClicked = () => {
    navigate("../eventos");
  };

  useEffect(() => {
    refresh();
  }, []);

  if (!add) return null;

  return (
    <div className="container">
      <div className="header1">Anuncio</div>
      <div>
        <button onClick={handleAddClicked} className="btn btn-secondary">
          Anuncio
        </button>
        <button
          onClick={handleEventsClicked}
          className="btn btn-secondary  ms-1"
        >
          Eventos
        </button>
      </div>
      <AddControl add={add} onUpdate={refresh} />
    </div>
  );
};

export default Page;
