import { useNavigate } from "react-router-dom";

import logo from "../../../images/Logo Mandame Inbox 03.PNG";
import registrame from "../../../images/registrame.png";
import apoyodiseno from "../../../images/apoyo-diseno.png";
import micuenta from "../../../images/mi-cuenta.png";
import { useUserContext } from "../../contexts/user.context";

const Page = () => {
  const navigate = useNavigate();
  const { signOut } = useUserContext();

  const handleSignupClicked = () => {
    signOut();
    navigate("/miembros/usuario/registrarme");
  };

  const handleSigninClicked = () => {
    signOut();
    navigate("/miembros/usuario/ingresar");
  };

  const handleDesignClicked = () => {
    navigate("diseno");
  };

  return (
    <div className="container">
      <div className="row">
        <h1 className="header1">Registrate en Mándame Inbox</h1>
      </div>
      <div
        className="row"
        style={{ color: "gray", fontSize: 20, textAlign: "justify" }}
      >
        <p>
          Para registrarse es completamente GRATIS y NO necesitas dar de alta
          ninguna tarjeta bancaria para hacerlo.
        </p>
        <p>
          Somos una Revista de Publicidad Completamente DIGITAL y nos
          distribuimos diariamente por medio de WhatsApp, FaceBook e Instagram
        </p>
        <p>
          Nos publicamos en estas zonas: Santiago, San Pedro, Santa Catarina,
          García, Monterrey, San Nicolás, Guadalupe, Apodaca y Escobedo.
        </p>
      </div>

      <div style={{ textAlign: "center" }}>
        <img
          src={logo}
          className="img-fluid"
          alt=""
          style={{ maxHeight: 80, marginTop: 40, marginBottom: 40 }}
        />
      </div>

      <div
        className="row"
        style={{
          marginBottom: 50,
        }}
      >
        <div className="col-sm" style={{ textAlign: "center" }}>
          <button onClick={handleSignupClicked} className="mybtn btn">
            <img src={registrame} alt="" />
            <div style={{ fontSize: 20, fontWeight: "bold" }}>Registrarme</div>
            <div style={{ maxWidth: 250 }}>
              Ingresa tu email y elige una contraseña que usarás aqui.
            </div>
          </button>
        </div>
        {/* <div className="col-sm" style={{ textAlign: "center" }}>
          <button onClick={handleSigninClicked} className="mybtn btn">
            <img src={micuenta} alt="" />
            <div style={{ fontSize: 20, fontWeight: "bold" }}>Soy Miembro</div>
            <div style={{ maxWidth: 250 }}>
              Ya estoy registrado, quiero ver o modificar mi anuncio.
            </div>
          </button>
        </div>
        <div className="col-sm" style={{ textAlign: "center" }}>
          <button onClick={handleDesignClicked} className="mybtn btn">
            <img src={apoyodiseno} alt="" />
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              Apoyo en Diseño
            </div>
            <div style={{ maxWidth: 250 }}>
              No tengo diseño para mi anuncio y quiero solicitar que me hagan
              uno.
            </div>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Page;
