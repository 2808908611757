import ApiServerClient from "../../../utils/apiserver.client";
import ApiserverAdminClient from "./apiserver.admin.client";

import { invalidParams, notFoundParams } from "./test.context";

const apiserverAdminClient = new ApiserverAdminClient();
const apiServerClient = new ApiServerClient();

const testFunction = async (testCtx, name, fn, params) => {
  // test with invalid params
  for (let outerParam of params) {
    for (let invalidParam of outerParam.invalidParams) {
      const funcParams = [];
      for (var innerParam of params) {
        if (outerParam === innerParam) {
          funcParams.push(invalidParam);
        } else {
          funcParams.push(innerParam.validParam);
        }
      }

      await testCtx.assert.throwException(
        `${name} with invalid ${outerParam.name}`,
        async () => {
          const result = await fn(...funcParams);

          if (result.results) {
            if (result.results.some((r) => r.state === "error")) {
              throw new Error("Multi result error");
            }
          }
        },
        (err) => err === "Error: Parametro invalido"
      );
    }
  }

  // test with not found params
  for (let outerParam of params) {
    if (!outerParam.notFoundParam) continue;

    const funcParams = [];
    for (let innerParam of params) {
      if (outerParam === innerParam) {
        funcParams.push(innerParam.notFoundParam);
      } else {
        funcParams.push(innerParam.validParams[0]);
      }
    }

    await testCtx.assert.throwException(
      `${name} with not found ${outerParam.name}`,
      async () => {
        const result = await fn(...funcParams);

        if (result?.results) {
          if (result.results.some((r) => r.state === "error")) {
            throw new Error("Multi result error");
          }
        }
      },
      (err) =>
        err.endsWith("no encontrado") || err === "Error: Lista de errores"
      //|| err === "Error: Usuario no tiene permiso"
    );
  }

  // test with valid params
  const results = [];
  {
    const funcParams = [];
    for (let param of params) {
      funcParams.push(param.validParams[0]);
    }

    const result = await testCtx.assert.throwNoException(
      `${name}`,
      async () => await fn(...funcParams)
    );

    results.push(result);
  }

  for (let outerParam of params) {
    for (
      let validParamIndex = 1;
      validParamIndex < outerParam.validParams.length;
      validParamIndex++
    ) {
      const funcParams = [];
      for (let innerParam of params) {
        if (outerParam === innerParam) {
          funcParams.push(outerParam.validParams[validParamIndex]);
        } else {
          funcParams.push(innerParam.validParams[0]);
        }
      }

      const result = await testCtx.assert.throwNoException(
        `${name}`,
        async () => await fn(...funcParams)
      );

      results.push(result);
    }
  }

  return results;
};

function getParams(func, path) {
  // String representation of the function code
  var str = func.toString();

  // Remove comments of the form /* ... */
  // Removing comments of the form //
  // Remove body of the function { ... }
  // removing '=>' if func is arrow function
  // removing 'async' if func is arrow function
  str = str
    .replace(/\/\*[\s\S]*?\*\//g, "")
    .replace(/\/\/(.)*/g, "")
    .replace(/{[\s\S]*}/, "")
    .replace(/=>/g, "")
    .replace(/async/g, "")
    .trim();

  let result;
  if (str.indexOf("(") >= 0) {
    // Start parameter names after first '('
    var start = str.indexOf("(") + 1;

    // End parameter names is just before last ')'
    var end = str.length - 1;

    result = str.substring(start, end).split(", ");
  } else {
    result = str.split(", ");
  }

  var params = [];

  result.forEach((element) => {
    // Removing any default value
    element = element.replace(/=[\s\S]*/g, "").trim();

    if (element.length > 0) params.push(element);
  });

  return params;
}

const recurseObject = (o, defs = [], paths = []) => {
  const keys = Object.keys(o);
  for (var key of keys) {
    paths.push(key);
    const childO = o[key];

    if (typeof childO === "function") {
      const path = paths.join(".");

      const params = getParams(childO, path);

      defs.push({
        func: childO,
        params,
        path,
      });
    } else {
      recurseObject(childO, defs, paths);
    }
    paths.pop();
  }

  return defs;
};

const testUserEndpoint = async (testCtx, imageFile) => {
  testCtx.headers.insert("User endpoints");
  await testFunction(testCtx, "Signup user", apiServerClient.user.signup, [
    {
      name: "name",
      invalidParams: invalidParams.string,
      validParams: ["Lasse"],
    },
    {
      name: "email",
      invalidParams: invalidParams.string,
      validParams: ["lassesundjohansen@gmail.com"],
    },
    {
      name: "password",
      invalidParams: invalidParams.string,
      validParams: ["hello"],
    },
  ]);

  const users = await apiserverAdminClient.util.find("User", {
    email: "lassesundjohansen@gmail.com",
  });

  testCtx.assert.isArrayAndHasElements("users", users);

  let [auth] = await testFunction(
    testCtx,
    "Verify email",
    apiServerClient.user.verifyemail,
    [
      {
        name: "userId",
        invalidParams: invalidParams.objectId,
        validParams: [users[0]._id],
      },
      {
        name: "password",
        invalidParams: invalidParams.string,
        validParams: ["hello"],
      },
    ]
  );

  [auth] = await testFunction(
    testCtx,
    "Signin user by credentials",
    apiServerClient.user.signinByCredentials,
    [
      {
        name: "email",
        notFoundParam: "lasse2@expertsys.com.mx",
        invalidParams: invalidParams.string,
        validParams: ["lassesundjohansen@gmail.com"],
      },
      {
        name: "password",
        notFoundParam: "non existing password",
        invalidParams: invalidParams.string,
        validParams: ["hello"],
      },
    ]
  );

  testCtx.assert.isObject("auth", auth);

  [auth] = await testFunction(
    testCtx,
    "Signin user by token",
    apiServerClient.user.signinByToken,
    [
      {
        name: "token",
        invalidParams: invalidParams.string,
        validParams: [auth.token],
      },
    ]
  );

  testCtx.assert.isObject("auth", auth);

  await testFunction(
    testCtx,
    "Send lost password",
    apiServerClient.user.sendLostPassword,
    [
      {
        name: "email",
        invalidParams: invalidParams.string,
        validParams: ["lassesundjohansen@gmail.com"],
      },
    ]
  );
};

// const testReleaseEndpoint = async (testCtx, imageFile) => {
//   testCtx.headers.insert("Release endpoints");
//   await service.user.signup("Lasse", "lassesundjohansen@gmail.com", "hello");

//   const users = await adminservice.util.find("User", {
//     email: "lassesundjohansen@gmail.com",
//   });

//   const { user } = await service.user.verifyemail(users[0]._id, "hello");

//   const [release] = await testFunction(
//     testCtx,
//     "Get latest release",
//     service.release.getLatest,
//     []
//   );

//   testCtx.assert.isObject("release", release);
// };

// const testHelpEndpoint = async (testCtx, imageFile) => {
//   testCtx.headers.insert("Help endpoints");
//   await service.user.signup("Lasse", "lassesundjohansen@gmail.com", "hello");

//   const users = await adminservice.util.find("User", {
//     email: "lassesundjohansen@gmail.com",
//   });

//   const { user } = await service.user.verifyemail(users[0]._id, "hello");

//   await testFunction(
//     testCtx,
//     "Send help about design",
//     service.help.sendHelpAboutDesignEmail,
//     [
//       {
//         name: "name",
//         invalidParams: invalidParams.string,
//         validParams: ["Lasse"],
//       },
//       {
//         name: "email",
//         invalidParams: invalidParams.string,
//         validParams: ["lassesundjohansen@gmail.com"],
//       },
//       {
//         name: "whatsapp",
//         invalidParams: invalidParams.string,
//         validParams: ["8180442505"],
//       },
//     ]
//   );
// };

// const testCategoryEndpoint = async (testCtx, imageFile) => {
//   testCtx.headers.insert("Category endpoints");
//   await service.user.signup("Lasse", "lassesundjohansen@gmail.com", "hello");

//   const users = await adminservice.util.find("User", {
//     email: "lassesundjohansen@gmail.com",
//   });

//   let auth = await service.user.verifyemail(users[0]._id, "hello");

//   const [categories] = await testFunction(
//     testCtx,
//     "Get all categories",
//     service.category.getAll,
//     []
//   );

//   testCtx.assert.isArrayAndHasElements("categories", categories);

//   auth = await service.user.signinByCredentials(
//     "z_irlanda@hotmail.com",
//     "test"
//   );

//   let [category] = await testFunction(
//     testCtx,
//     "Get category by id",
//     service.category.getById,
//     [
//       {
//         name: "categoryId",
//         invalidParams: invalidParams.objectId,
//         validParams: [categories[0]._id],
//       },
//     ]
//   );

//   testCtx.assert.isObject("category", category);

//   [category] = await testFunction(testCtx, "Update", service.category.update, [
//     {
//       name: "categoryId",
//       invalidParams: invalidParams.objectId,
//       validParams: [categories[0]._id],
//     },
//     {
//       name: "file",
//       invalidParams: [[], {}, ""],
//       validParams: [imageFile],
//     },
//   ]);
// };

// const testAreaEndpoint = async (testCtx, imageFile) => {
//   testCtx.headers.insert("Area endpoints");
//   await service.user.signup("Lasse", "lassesundjohansen@gmail.com", "hello");

//   const users = await adminservice.util.find("User", {
//     email: "lassesundjohansen@gmail.com",
//   });

//   await service.user.verifyemail(users[0]._id, "hello");

//   const [areas] = await testFunction(
//     testCtx,
//     "Get all areas",
//     service.area.getAll,
//     []
//   );

//   testCtx.assert.isArrayAndHasElements("areas", areas);

//   await service.user.signinByCredentials("z_irlanda@hotmail.com", "test");

//   let [area] = await testFunction(
//     testCtx,
//     "Get area by id",
//     service.area.getById,
//     [
//       {
//         name: "areaId",
//         invalidParams: invalidParams.objectId,
//         validParams: [areas[0]._id],
//       },
//     ]
//   );

//   testCtx.assert.isObject("area", area);

//   [area] = await testFunction(testCtx, "Update", service.area.update, [
//     {
//       name: "areaId",
//       invalidParams: invalidParams.objectId,
//       validParams: [areas[0]._id],
//     },
//     {
//       name: "file",
//       invalidParams: [[], {}, ""],
//       validParams: [imageFile],
//     },
//   ]);
// };

// const testAddEndpoint = async (testCtx, imageFile) => {
//   testCtx.headers.insert("Add endpoints");
//   await service.user.signup("Lasse", "lassesundjohansen@gmail.com", "hello");

//   const users = await adminservice.util.find("User", {
//     email: "lassesundjohansen@gmail.com",
//   });

//   const { user } = await service.user.verifyemail(users[0]._id, "hello");

//   const categories = await service.category.getAll();

//   const areas = await service.area.getAll();

//   let [add] = await testFunction(testCtx, "Create", service.add.create, [
//     {
//       name: "note",
//       invalidParams: invalidParams.string,
//       validParams: [""],
//     },
//     {
//       name: "areaId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [areas[0]._id],
//     },
//     {
//       name: "imageFile",
//       invalidParams: ["", {}, []],
//       validParams: [imageFile],
//     },
//     {
//       name: "categoryId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [categories[0]._id],
//     },
//     {
//       name: "backgroundColor",
//       invalidParams: invalidParams.string,
//       validParams: ["#ffffff"],
//     },
//     {
//       name: "promotionCode",
//       invalidParams: invalidParams.string,
//       validParams: [""],
//     },
//   ]);

//   testCtx.assert.isObject("add", add);

//   let [adds] = await testFunction(
//     testCtx,
//     "Get my adds",
//     service.add.getByUser,
//     []
//   );

//   testCtx.assert.isArrayAndHasElements("adds", adds);

//   [add] = await testFunction(testCtx, "Get by id", service.add.getById, [
//     {
//       name: "addId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [add._id],
//     },
//   ]);

//   testCtx.assert.isObject("add", add);

//   await testFunction(
//     testCtx,
//     "Send payment instructions",
//     service.add.sendPaymentInstructions,
//     [
//       {
//         name: "addId",
//         notFoundParam: notFoundParams.objectId,
//         invalidParams: invalidParams.objectId,
//         validParams: [add._id],
//       },
//     ]
//   );

//   await testFunction(testCtx, "Delete", service.add.delete, [
//     {
//       name: "addId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [add._id],
//     },
//   ]);

//   add = await service.add.create(
//     "",
//     areas[0]._id,
//     imageFile,
//     categories[0]._id,
//     "#ffffff",
//     ""
//   );

//   await service.user.signinByCredentials("z_irlanda@hotmail.com", "test");

//   [adds] = await testFunction(testCtx, "Get my adds", service.add.getAll, []);

//   testCtx.assert.isArrayAndHasElements("adds", adds);

//   let [currentAreas] = await testFunction(
//     testCtx,
//     "Get current areas",
//     service.add.getCurrentAreas,
//     []
//   );

//   testCtx.assert.isObject("areas", currentAreas);

//   [adds] = await testFunction(
//     testCtx,
//     "Get current by area",
//     service.add.getCurrentByArea,
//     [
//       {
//         name: "area",
//         invalidParams: invalidParams.string,
//         validParams: [adds[0].reviewVersion.area],
//       },
//     ]
//   );

//   testCtx.assert.isObject("adds", adds);

//   // set balance = 0, so next calls will work
//   await adminservice.util.updateMany("Add", { _id: add._id }, { balance: 0 });

//   await testFunction(testCtx, "Reject", service.add.reject, [
//     {
//       name: "addId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [add._id],
//     },
//   ]);

//   await service.user.signinByCredentials(
//     "lassesundjohansen@gmail.com",
//     "hello"
//   );

//   await testFunction(testCtx, "Update", service.add.update, [
//     {
//       name: "addId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [add._id],
//     },
//     {
//       name: "note",
//       invalidParams: invalidParams.string,
//       validParams: [""],
//     },
//     {
//       name: "areaId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [areas[0]._id],
//     },
//     {
//       name: "imageFile",
//       invalidParams: ["", {}, []],
//       validParams: [imageFile, undefined],
//     },
//     {
//       name: "categoryId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [categories[0]._id],
//     },
//     {
//       name: "backgroundColor",
//       invalidParams: invalidParams.string,
//       validParams: ["#ffffff"],
//     },
//   ]);

//   await service.user.signinByCredentials("z_irlanda@hotmail.com", "test");

//   await testFunction(testCtx, "Accept", service.add.accept, [
//     {
//       name: "addId",
//       notFoundParam: notFoundParams.objectId,
//       invalidParams: invalidParams.objectId,
//       validParams: [add._id],
//     },
//   ]);

//   await service.user.signinByCredentials(
//     "lassesundjohansen@gmail.com",
//     "hello"
//   );
// };

const performTests = async (testCtx, imageFile) => {
  const testFunctions = [
    testUserEndpoint,
    // testReleaseEndpoint,
    // testHelpEndpoint,
    // testCategoryEndpoint,
    // testAreaEndpoint,
    // testAddEndpoint,
  ];

  for (var testFunction of testFunctions) {
    await apiserverAdminClient.util.deleteMany("User", {
      email: "lassesundjohansen@gmail.com",
    });

    await testFunction(testCtx, imageFile);
  }
};

export default performTests;
