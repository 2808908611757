import { useState } from "react";

import logo from "../../../images/Logo Mandame Inbox 01.PNG";
import enviar from "../../../images/Enviar3.PNG";

import validateUtil from "../../../utils/validate.util";
import { useMessageContext } from "../../contexts/message.context";
import { useExceptionContext } from "../../contexts/exception.context";
import { useUserContext } from "../../contexts/user.context";

const Page = () => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const handleSendClicked = async () => {
    try {
      await apiServerClient.visitor.help.sendHelpAboutDesignEmail(
        name.trim(),
        email.trim(),
        whatsapp.trim()
      );

      setName("");
      setEmail("");
      setWhatsapp("");
      showSuccess("¡Gracias! En breve nos comunicaremos contigo.");
    } catch (ex) {
      handleException(ex);
    }
  };

  const isFormOK = () => {
    if (!validateUtil.isStringWithContent(name)) return false;
    if (!validateUtil.isEmail(email)) return false;
    if (!validateUtil.isWhatsapp(whatsapp)) return false;

    return true;
  };

  return (
    <div className="container" style={{ maxWidth: 600, marginTop: 40 }}>
      <div className="row">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div style={{ fontSize: 30, color: "#ff5f29", fontWeight: "bold" }}>
          ¿No cuentas con un diseño para tu anuncio? ¡Aquí te ayudamos!
        </div>
        <div
          className="row"
          style={{ color: "gray", fontSize: 20, textAlign: "justify" }}
        >
          <p>
            ¡Gracias por apoyarte en nosotros! Con gusto te ayudamos a elaborar
            el diseño para tu anuncio, envíanos tus datos para ponernos en
            contacto contigo.
          </p>
        </div>
      </div>

      <div className="container" style={{ maxWidth: 600 }}>
        <div className="row">
          <div style={{ fontSize: 20, fontWeight: "bold" }}>
            Nombre del contacto:
          </div>
          <div>
            <input
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div style={{ fontSize: 20, fontWeight: "bold" }}>E-mail:</div>
          <div>
            <input
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div style={{ fontSize: 20, fontWeight: "bold" }}>WhatsApp:</div>
          <div>
            <input
              className="form-control"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              marginBottom: 50,
            }}
          >
            <button
              onClick={handleSendClicked}
              className="mybtn btn"
              disabled={!isFormOK()}
            >
              <img
                src={enviar}
                alt=""
                className="btnImg80"
                style={{ marginTop: "30px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
