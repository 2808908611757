import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import logo from "../../../images/Logo Mandame Inbox 01.PNG";
import comprarInternet from "../../../images/Comprar Internet.jpg";

import { useExceptionContext } from "../../contexts/exception.context";
import { useUserContext } from "../../contexts/user.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const navigate = useNavigate();
  const [areas, setAreas] = useState([]);

  const refresh = async () => {
    try {
      const areas = await apiServerClient.visitor.area.getUsed();

      setAreas(areas);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleAreaClicked = (area) => {
    navigate(`./${area.name}`);
  };

  const cells = areas.map((area) => {
    return (
      <div className="col-sm area">
        <Link to={`./${area.name}`}>
          <img
            src={area.buttonFile.url}
            className="img-fluid"
            alt={area.name}
            style={{ maxHeight: 100 }}
          />
        </Link>
      </div>
    );
  });

  // fill up cells to have a multiple of 3
  for (var i = 0; i < cells.length % 3; i++) {
    cells.push(<div className="col-sm area"></div>);
  }

  const rows = [];

  for (var i = 0; i < cells.length; i += 3) {
    rows.push(
      <div className="row" key={i}>
        {cells[i + 0]}
        {cells[i + 1]}
        {cells[i + 2]}
      </div>
    );
  }

  return (
    <div>
      <div className="container" style={{ maxWidth: 600 }}>
        <div className="row" style={{ textAlign: "center" }}>
          <img
            src={logo}
            className="img-fluid"
            alt=""
            style={{ marginBottom: 40 }}
          />
        </div>
      </div>
      <div className="container" style={{ textAlign: "center" }}>
        <div className="row">
          <div style={{ fontSize: 30, color: "#ff5f29", fontWeight: "bold" }}>
            <p>SELECCIONA UNA DE LAS ÁREAS</p>
          </div>
        </div>
      </div>
      <div className="container" style={{ maxWidth: 600 }}>
        <div className="row" style={{ marginTop: 40 }}>
          {rows}
        </div>
        <div className="row" style={{ marginTop: 80 }}>
          <img src={comprarInternet} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Page;
