import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

//import index from "../../../images/index.jpg";

import { useExceptionContext } from "../../contexts/exception.context";
import { useUserContext } from "../../contexts/user.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const { name } = useParams();
  const [area, setArea] = useState([]);
  const [categories, setCategories] = useState([]);
  const [settings, setSettings] = useState();
  const indexRef = useRef();

  const refresh = async () => {
    try {
      const settings = await apiServerClient.visitor.settings.getAll();
      const area = await apiServerClient.visitor.area.getByName(name);
      const categories = await apiServerClient.visitor.category.getAll();
      const adds = await apiServerClient.visitor.add.getByArea(name);

      const categories2 = {};
      for (var category of categories) {
        category.adds = [];
        categories2[category.name] = { db: category, adds: [] };
      }

      for (var add of adds) {
        categories2[add.currentVersion.category].adds.push(add);
      }

      const categories3 = Object.values(categories2).sort(
        (a, b) => a.db.number - b.db.number
      );

      setSettings(settings);
      setArea(area);
      setCategories(categories3);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const renderCategories = () => {
    return categories.map((c) => renderCategory(c));
  };

  const renderCategory = (category) => {
    return (
      <div key={category._id}>
        <div className="page" id={category.db._id}>
          <img
            src={category.db.headerFile.url}
            className="img-fluid w-100"
            alt=""
          />
        </div>
        {category.adds.length === 0 ? (
          <div className="page">
            <img
              src={settings.noAddsFile.url}
              className="img-fluid w-100"
              alt=""
            />
          </div>
        ) : (
          category.adds.map((a) => (
            <div className="page" key={a._id}>
              <img
                src={a.currentVersion.imageFile.url}
                className="img-fluid w-100"
                alt=""
              />
              {a.currentVersion.note && (
                <div
                  style={{
                    backgroundColor: `${a.currentVersion.backgroundColor}`,
                    padding: 10,
                  }}
                  className="defaultFont"
                >
                  {a.currentVersion.note}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    );
  };

  const getCategory = (x, y) => {
    for (var category of categories) {
      if (
        x >= category.db.bounds.x &&
        x <= category.db.bounds.x + category.db.bounds.w &&
        y >= category.db.bounds.y &&
        y <= category.db.bounds.y + category.db.bounds.h
      ) {
        return category;
      }
    }

    return null;
  };

  const handleIndexMouseMoved = (e) => {
    if (!indexRef.current) return;

    const scale = indexRef.current.width / indexRef.current.naturalWidth;
    const x = (e.pageX - e.target.offsetLeft) / scale;
    const y = (e.pageY - e.target.offsetTop) / scale;

    const category = getCategory(x, y);

    e.target.style.cursor = category ? "pointer" : "default";
  };

  const handleIndexClicked = (e) => {
    if (!indexRef.current) return;

    const scale = indexRef.current.width / indexRef.current.naturalWidth;
    const x = (e.pageX - e.target.offsetLeft) / scale;
    const y = (e.pageY - e.target.offsetTop) / scale;

    const category = getCategory(x, y);

    if (category) {
      console.log(
        category.db._id,
        document.getElementById(category.db._id.toString())
      );
      document.getElementById(category.db._id.toString()).scrollIntoView();
    }
  };

  if (!settings) return null;

  return (
    <div
      style={{
        backgroundColor: "#DDDDDD",
        marginTop: -30,
        paddingTop: 30,
        marginBottom: -30,
        paddingBottom: 30,
      }}
    >
      <div className="container" style={{ maxWidth: 800 }}>
        <div className="page">
          <img src={area.coverFile?.url} className="img-fluid w-100" alt="" />
        </div>
        <div className="page">
          <img
            ref={indexRef}
            src={settings.indexFile.url}
            className="img-fluid w-100"
            alt=""
            onMouseMove={handleIndexMouseMoved}
            onClick={handleIndexClicked}
          />
        </div>
        {renderCategories()}
      </div>
    </div>
  );
};

export default Page;
