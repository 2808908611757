import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useUserContext } from "../../../contexts/user.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useNavigate, useParams } from "react-router-dom";
import { useMessageContext } from "../../../contexts/message.context";
import addUtil from "../../../../utils/add.util";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { user, apiServerClient } = useUserContext();
  const { showSuccess } = useMessageContext();
  const { addId } = useParams();
  const [add, setAdd] = useState();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [reason, setReason] = useState("");

  const refresh = async () => {
    if (!user) return;

    try {
      const add = await apiServerClient.admin.add.getById(addId);

      setAdd(add);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, [user]);

  const getVersion = (version, name) => {
    if (!version) return null;

    return (
      <div>
        <div>
          <strong>Tipo: </strong>
          {name}
        </div>
        <img
          src={version.imageFile.url}
          onClick={() => setShowImageModal(true)}
          style={{
            width: "200px",
            height: "200px",
            objectFit: "contain",
            cursor: "pointer",
          }}
        />
        {version.note.trim().length > 0 && (
          <div>
            <div>
              <strong>Nota: </strong>
              {version.note.split("\n").map((line, i) => (
                <span key={i}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
            <div>
              <strong>Color de fondo: </strong>
              <span
                style={{
                  width: 30,
                  border: "1px solid black",
                  backgroundColor: version.backgroundColor,
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </div>
        )}
        <div>
          <strong>Categoría: </strong>
          {version.category}
        </div>
        <div>
          <strong>Áreas: </strong>
          {version.areas.join(", ")}
        </div>
      </div>
    );
  };

  const handleAcceptClicked = async () => {
    try {
      await apiServerClient.admin.add.accept(add._id);
      refresh();
      showSuccess("Anuncio aceptado");
    } catch (ex) {
      handleException(ex);
    }
  };

  const handleRejectClicked = async () => {
    try {
      await apiServerClient.admin.add.reject(add._id, reason);
      setShowRejectModal(false);
      refresh();
      showSuccess("Anuncio rechezado");
    } catch (ex) {
      handleException(ex);
    }
  };

  const isRejectFormOk = () => {
    if (!reason.trim().length) return false;

    return true;
  };

  const getButtons = () => {
    if (add.status.isReviewPending) {
      return (
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleAcceptClicked(add)}
            style={{ marginRight: 4 }}
          >
            Aceptar
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setShowRejectModal(true)}
          >
            Rechezar
          </button>
        </div>
      );
    }
    return null;
  };

  if (!add) return null;

  return (
    <div className="container">
      <div className="header1">Revision</div>
      <div className="add" key={add._id}>
        <div>
          <strong>Id: </strong>
          {add.id}
        </div>
        {add.endDate && (
          <div>
            <strong>Periodo: </strong>
            {add.startDate} - {add.endDate}
          </div>
        )}
        <div>
          <strong>Estatus: </strong>
          {addUtil.getStatus(add)}
        </div>
        {getVersion(add.currentVersion, "Anuncio Vigente")}
        {getVersion(add.reviewVersion, "Nuevo Anuncio")}

        <div>{getButtons(add)}</div>
      </div>
      <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rechazar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="form-label">Razon</label>
            <input
              className="form-control"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowRejectModal(false)}
          >
            Cancelar
          </button>
          <button
            className="btn btn-danger"
            onClick={handleRejectClicked}
            disabled={!isRejectFormOk()}
          >
            Rechezar
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Anuncio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img
              src={add.reviewVersion?.imageFile?.url}
              style={{ width: "100%" }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Page;
