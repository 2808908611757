import { useState, useEffect, useRef } from "react";
import { useUserContext } from "../../../contexts/user.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { user, apiServerClient } = useUserContext();
  const [allAreas, setAllAreas] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [areas, setAreas] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const addsRef = useRef([]);

  const refresh = async () => {
    if (!user) return;

    try {
      const categories = await apiServerClient.admin.category.getAll();
      const areas = await apiServerClient.admin.area.getAll();
      const adds = await apiServerClient.admin.add.getReviews();

      addsRef.current = adds;
      setAllCategories(categories);
      setCategories(categories.map((c) => c.name));
      setAllAreas(areas);
      setAreas(areas.map((c) => c.name));
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, [user]);

  const getSelectValues = (select) => {
    var result = [];
    var options = select && select.options;
    var opt;

    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
  };

  const onAddClicked = (add) => {
    navigate(`./${add._id}`);
  };

  const handleCategoryChanged = (e) => {
    const categories = getSelectValues(e.target);
    setCategories(categories);
  };

  const handleAreaChanged = (e) => {
    const areas = getSelectValues(e.target);
    setAreas(areas);
  };

  const filteredAdds = addsRef.current.filter((a) => {
    return (
      areas.some((area) => a.reviewVersion.areas.includes(area)) &&
      categories.includes(a.reviewVersion.category)
    );
  });

  return (
    <div className="container">
      <div className="header1">Revisiones</div>
      <button onClick={refresh} className="btn btn-primary">
        Refrescar
      </button>
      <div className="filters">
        <div>
          <label>Categorías</label>
          <div>
            <select
              multiple={true}
              onChange={handleCategoryChanged}
              className="form-control"
            >
              {allCategories.map((c) => (
                <option key={c._id} selected={categories.includes(c.name)}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Areas</label>
            <div>
              <select
                multiple={true}
                onChange={handleAreaChanged}
                className="form-control"
              >
                {allAreas.map((a) => (
                  <option key={a._id} selected={areas.includes(a.name)}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table className="table table-striped table-clickable">
          <thead>
            <tr>
              <th>Id</th>
            </tr>
          </thead>
          <tbody>
            {filteredAdds.map((a) => {
              return (
                <tr key={a._id} onClick={() => onAddClicked(a)}>
                  <td>{a.id}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Page;
