import { useState, useEffect } from "react";
import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";
import { DateTime } from "luxon";

const typeToString = (type) => {
  switch (type) {
    case "generateOne":
      return "Generar una revista";
    case "generateAll":
      return "Generar revistas";
    default:
      return "N/A";
  }
};

const Page = () => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [indexFile, setIndexFile] = useState();
  const [oneMagazineCoverFile, setOneMagazineCoverFile] = useState();
  const [noAddsFile, setNoAddsFile] = useState();
  const [settings, setSettings] = useState();
  const [magazines, setMagazines] = useState([]);

  const refresh = async () => {
    try {
      const settings = await apiServerClient.admin.settings.getAll();
      setSettings(settings);
      setIndexFile(settings.indexFile);
      setNoAddsFile(settings.noAddsFile);
      setOneMagazineCoverFile(settings.oneMagazineCoverFile);
    } catch (ex) {
      handleException(ex);
    }
  };

  const refreshMagazines = async () => {
    const magazines = await apiServerClient.admin.magazine.getNewest(5);

    setMagazines(magazines);
  };

  useEffect(() => {
    refresh();
    refreshMagazines();
  }, []);

  const isFormOk = () => {
    return true;
  };

  const hasFormChanged = () => {
    if (settings.indexFile != indexFile) return true;
    if (settings.noAddsFile != noAddsFile) return true;
    if (settings.oneMagazineCoverFile != oneMagazineCoverFile) return true;

    return false;
  };

  const onUpdateClicked = async () => {
    try {
      await apiServerClient.admin.settings.update(
        indexFile,
        noAddsFile,
        oneMagazineCoverFile
      );
      showSuccess("Configuracion actualizado");
    } catch (ex) {
      handleException(ex);
    }
  };

  const onGenerateClicked = async () => {
    try {
      await apiServerClient.admin.magazine.generate("all");
      showSuccess(
        "Las revistas se estan generando. Recibiras un correo con un enlace para bajarlas."
      );
    } catch (ex) {
      handleException(ex);
    }
    //window.open("/api/v1/magazine/generateAll", "_blank");
  };

  const onGenerateOneClicked = async () => {
    try {
      await apiServerClient.admin.magazine.generate("one");
      showSuccess(
        "La revista se esta generando. Recibiras un correo con un enlace para bajarla."
      );
    } catch (ex) {
      handleException(ex);
    }
    //window.open("/api/v1/magazine/generateOne", "_blank");
  };

  if (!settings) return null;

  return (
    <div className="container">
      <div className="header1">Revista</div>
      <div className="header2">Configuracion</div>
      <div className="form-group mb-2">
        <label className="form-label">Archivo del indice</label>
        <div className="input-group">
          <input
            className="form-control"
            value={indexFile?.name || ""}
            disabled={true}
          ></input>
          <label className="btn btn-primary">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                e.target.files.length && setIndexFile(e.target.files[0])
              }
              accept=".png,.jpg"
            />
            Escoger
          </label>
        </div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">
          Archivo de no existe anuncios en categoria
        </label>
        <div className="input-group">
          <input
            className="form-control"
            value={noAddsFile?.name || ""}
            disabled={true}
          ></input>
          <label className="btn btn-primary">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                e.target.files.length && setNoAddsFile(e.target.files[0])
              }
              accept=".png,.jpg"
            />
            Escoger
          </label>
        </div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">
          Archivo de la portada de una revista
        </label>
        <div className="input-group">
          <input
            className="form-control"
            value={oneMagazineCoverFile?.name || ""}
            disabled={true}
          ></input>
          <label className="btn btn-primary">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                e.target.files.length &&
                setOneMagazineCoverFile(e.target.files[0])
              }
              accept=".png,.jpg"
            />
            Escoger
          </label>
        </div>
      </div>

      <div className="form-group mb-2">
        <button
          onClick={onUpdateClicked}
          disabled={!isFormOk() || !hasFormChanged()}
          className="btn btn-primary"
        >
          Actualizar
        </button>
      </div>

      <div className="header2">Generacion</div>
      <div>
        <div className="form-group mb-2">
          <button onClick={onGenerateClicked} className="btn btn-primary">
            Generar revistas
          </button>
          <button
            onClick={onGenerateOneClicked}
            className="btn btn-primary  ms-1"
          >
            Generar una revista
          </button>
        </div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Solicitado</th>
            <th>Generado</th>
          </tr>
        </thead>
        <tbody>
          {magazines.map((m) => (
            <tr>
              <td>{typeToString(m.type)}</td>
              <td>
                {DateTime.fromISO(m.createdAt).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </td>
              <td>
                {m.processDate &&
                  DateTime.fromISO(m.processDate).toLocaleString(
                    DateTime.DATETIME_MED
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Page;
