import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import mandameinbox from "../../../../images/Logo Mandame Inbox 01.PNG";
import yes from "../../../../images/Si_2.JPG";
import no from "../../../../images/NO_2.jpg";

import { useUserContext } from "../../../contexts/user.context";
import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { showSuccess } = useMessageContext();
  const navigate = useNavigate();
  const { user, apiServerClient } = useUserContext();
  const { addId } = useParams();
  const [add, setAdd] = useState();
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    if (!user) return;

    const refresh = async () => {
      try {
        const add = await apiServerClient.customer.add.getById(addId);
        setAdd(add);
      } catch (ex) {
        handleException(ex);
      }
    };

    refresh();
  }, [user]);

  const onDeleteClicked = async () => {
    try {
      setIsWorking(true);
      await apiServerClient.customer.add.delete(addId);
      showSuccess("Anuncio borrado");
      navigate("./../..");
    } catch (ex) {
      handleException(ex);
      setIsWorking(false);
    }
  };

  const onCancelClicked = () => {
    navigate("./../..");
  };

  if (!add) return null;

  const version = add.reviewVersion || add.currentVersion;

  return (
    <div className="container centerApp">
      <img src={mandameinbox} alt="" style={{ maxHeight: 150 }} />
      <h1 className="header1" style={{ marginTop: 30, marginBottom: 30 }}>
        ¿Quieres borrar anuncio?
      </h1>
      {(add.status.isPublished || add.status.isApplyPaymentPending) && (
        <div className="alert alert-warning">
          <ul>
            <li>
              Aún cuentas con saldo a favor, al borrar perderás tanto el anuncio
              como el saldo. Si lo que deseas es modificar el anuncio, regresa
              al paso donde dice "Cambiar" para aprovechar tu saldo.
            </li>
          </ul>
        </div>
      )}

      <div>
        <div>
          <label className="form-label">Foto:</label>
          <div>
            <img
              src={version.imageFile.url}
              alt=""
              style={{
                width: "200px",
                height: "200px",
                objectFit: "contain",
              }}
            />
          </div>
        </div>

        {version.note.trim().length > 0 && (
          <div>
            <div>
              <label className="form-label">Nota:</label>
              <div>
                {version.note.split("\n").map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}
              </div>
            </div>

            <div>
              <label className="form-label">Color de fondo:</label>
              <div>
                <span
                  style={{
                    border: "1px solid black",
                    backgroundColor: version.backgroundColor,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
            </div>
          </div>
        )}

        <div>
          <label className="form-label">Elige una categoría de búsqueda:</label>
          <div>{version.category}</div>
        </div>

        <div>
          <label className="form-label">Publicarse en:</label>
          <div>{version.areas.join(", ")}</div>
        </div>

        <div style={{ marginTop: 30 }}>
          <button
            onClick={onDeleteClicked}
            className="mybtn"
            disabled={isWorking}
          >
            <img src={yes} alt="" className="img-fluid btnImg80" />
          </button>
          <button
            onClick={onCancelClicked}
            className="mybtn"
            disabled={isWorking}
          >
            <img src={no} alt="" className="img-fluid btnImg80" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page;
