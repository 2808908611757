import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

export default () => {
  const [users, setUsers] = useState([]);
  const [nameOrEmailOrAddId, setNameOrEmailOrAddId] = useState("");
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ months: 3 }).toISODate()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toISODate());
  const [searchByInterval, setSearchByInterval] = useState(false);
  const { handleException } = useExceptionContext();
  const { user, apiServerClient } = useUserContext();
  const navigate = useNavigate();

  const handleSearchClicked = async () => {
    try {
      if (searchByInterval) {
        const users = await apiServerClient.admin.user.getByInterval(
          DateTime.fromISO(startDate).toISO(),
          DateTime.fromISO(endDate).toISO()
        );
        setUsers(users);
      } else {
        const users = await apiServerClient.admin.user.getByNameOrEmailOrAddId(
          nameOrEmailOrAddId
        );
        setUsers(users);
      }
    } catch (ex) {
      handleException(ex);
    }
  };

  const handleUserClicked = (user) => {
    navigate(`./${user._id}`);
  };

  return (
    <div className="container">
      <div className="header1">Usuarios</div>

      <div className="input-group mb-2">
        <div className="input-group-text">
          <input
            className="form-check-input mt-0"
            type="radio"
            checked={searchByInterval}
            onChange={(e) => setSearchByInterval(e.target.checked)}
          />
        </div>

        <input
          className="form-control"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          className="form-control"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>

      <div className="input-group mb-2">
        <div className="input-group-text">
          <input
            className="form-check-input mt-0"
            type="radio"
            checked={!searchByInterval}
            onChange={(e) => setSearchByInterval(!e.target.checked)}
          />
        </div>
        <input
          className="form-control"
          value={nameOrEmailOrAddId}
          onChange={(e) => setNameOrEmailOrAddId(e.target.value)}
          placeholder="correo o nombre o id del anuncio"
        />
      </div>
      <div className="mb-2">
        <button className="btn btn-primary" onClick={handleSearchClicked}>
          Buscar
        </button>
      </div>

      <table className="table table-striped table-clickable">
        <thead>
          <tr>
            <th>Correo</th>
            <th>Nombre</th>
            <th>Verificado</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u._id} onClick={() => handleUserClicked(u)}>
              <td>{u.email}</td>
              <td>{u.name}</td>
              <td>{u.isVerified ? "Si" : ""}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
