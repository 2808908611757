import ApiServerClient from "../../../utils/apiserver.client";
import ApiserverAdminClient from "./apiserver.admin.client";

const apiServerClient = new ApiServerClient();
const apiserverAdminClient = new ApiserverAdminClient();
const apiServerClientAsOwner = new ApiServerClient();

apiServerClientAsOwner.user.signinByCredentials(
  "z_irlanda@hotmail.com",
  "test"
);

const disallowCreateAddIfNotSignin = async (testCtx, imageFile) => {
  testCtx.headers.insert("signupDisallowCreateAdd");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  const areas = await apiServerClient.area.getAll();

  const categories = await apiServerClient.category.getAll();

  await testCtx.assert.throwException("Create add", async () => {
    const add = await apiServerClient.add.create(
      "Hello",
      areas[0]._id,
      imageFile,
      categories[0]._id,
      "#ffffff",
      "test"
    );
    return add;
  });
};

const disallowSigninWithoutVerifyAccount = async (testCtx, imageFile) => {
  testCtx.headers.insert("signupDisallowSignin");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  await testCtx.assert.throwException("signin", async () => {
    await apiServerClient.user.signinByCredentials(
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });
};

const allowSigninAfterSignupAndVerify = async (testCtx, imageFile) => {
  testCtx.headers.insert("signupAndVerifyAllowSignin");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  const users = await apiserverAdminClient.util.find("User", {
    email: "lassesundjohansen@gmail.com",
  });

  testCtx.assert.isArrayAndHasElements("Users", users);

  await testCtx.assert.throwNoException("verify email", async () => {
    await apiServerClient.user.verifyemail(users[0]._id, "stupid");
  });

  await testCtx.assert.throwNoException("signin", async () => {
    await apiServerClient.user.signinByCredentials(
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });
};

const disallowSignup2AccountsWithSameEmail = async (testCtx, imageFile) => {
  testCtx.headers.insert("signupAndVerifyAllowSignin");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  await testCtx.assert.throwException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });
};

const disallowVerifyAfterCreateAddWithoutPayment = async (
  testCtx,
  imageFile
) => {
  testCtx.headers.insert("disallowVerifyAfterCreateAddWithoutPayment");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  const users = await apiserverAdminClient.util.find("User", {
    email: "lassesundjohansen@gmail.com",
  });

  testCtx.assert.isArrayAndHasElements("Users", users);

  await testCtx.assert.throwNoException("verify email", async () => {
    await apiServerClient.user.verifyemail(users[0]._id, "stupid");
  });

  const areas = await apiServerClient.area.getAll();

  const categories = await apiServerClient.category.getAll();

  const add = await testCtx.assert.throwNoException("Create add", async () => {
    const add = await apiServerClient.add.create(
      "Hello",
      areas[0]._id,
      imageFile,
      categories[0]._id,
      "#ffffff",
      ""
    );
    return add;
  });

  testCtx.assert.isObject("Add", add);

  await testCtx.assert.throwException("Accept", async () => {
    await apiServerClientAsOwner.add.accept(add._id);
  });
};

const allowVerifyAfterCreateAddWithPayment = async (testCtx, imageFile) => {
  testCtx.headers.insert("allowVerifyAfterCreateAddAndPayment");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  const users = await apiserverAdminClient.util.find("User", {
    email: "lassesundjohansen@gmail.com",
  });

  testCtx.assert.isArrayAndHasElements("Users", users);

  await testCtx.assert.throwNoException("verify email", async () => {
    await apiServerClient.user.verifyemail(users[0]._id, "stupid");
  });

  const areas = await apiServerClient.area.getAll();

  const categories = await apiServerClient.category.getAll();

  const add = await testCtx.assert.throwNoException("Create add", async () => {
    const add = await apiServerClient.add.create(
      "Hello",
      areas[0]._id,
      imageFile,
      categories[0]._id,
      "#ffffff",
      "test"
    );
    return add;
  });

  testCtx.assert.isObject("Add", add);

  await testCtx.assert.throwNoException("Accept", async () => {
    await apiServerClientAsOwner.add.accept(add._id);
  });
};

const disallowAddtimeAfterCreateAddWithoutPayment = async (
  testCtx,
  imageFile
) => {
  testCtx.headers.insert("disallowAddtimeAfterCreateAddWithoutPayment");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  const users = await apiserverAdminClient.util.find("User", {
    email: "lassesundjohansen@gmail.com",
  });

  testCtx.assert.isArrayAndHasElements("Users", users);

  await testCtx.assert.throwNoException("verify email", async () => {
    await apiServerClient.user.verifyemail(users[0]._id, "stupid");
  });

  const areas = await apiServerClient.area.getAll();

  const categories = await apiServerClient.category.getAll();

  const add = await testCtx.assert.throwNoException("Create add", async () => {
    const add = await apiServerClient.add.create(
      "Hello",
      areas[0]._id,
      imageFile,
      categories[0]._id,
      "#ffffff",
      ""
    );
    return add;
  });

  testCtx.assert.isObject("Add", add);

  await testCtx.assert.throwException("addTime", async () => {
    await apiServerClientAsOwner.add.addTime(add._id, "test");
  });
};

const allowAddtimeAfterVerify = async (testCtx, imageFile) => {
  testCtx.headers.insert("allowAddtimeAfterVerify");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  const users = await apiserverAdminClient.util.find("User", {
    email: "lassesundjohansen@gmail.com",
  });

  testCtx.assert.isArrayAndHasElements("Users", users);

  await testCtx.assert.throwNoException("verify email", async () => {
    await apiServerClient.user.verifyemail(users[0]._id, "stupid");
  });

  const areas = await apiServerClient.area.getAll();

  const categories = await apiServerClient.category.getAll();

  const add = await testCtx.assert.throwNoException("Create add", async () => {
    const add = await apiServerClient.add.create(
      "Hello",
      areas[0]._id,
      imageFile,
      categories[0]._id,
      "#ffffff",
      "test"
    );
    return add;
  });

  testCtx.assert.isObject("Add", add);

  await testCtx.assert.throwNoException("Accept", async () => {
    await apiServerClientAsOwner.add.accept(add._id);
  });

  await testCtx.assert.throwNoException("addTime", async () => {
    await apiServerClientAsOwner.add.addTime(add._id, "");
  });
};

const disallowCreate2OrdersWithSamePromo = async (testCtx, imageFile) => {
  testCtx.headers.insert("disallowAddtimeAfterCreateAddWithoutPayment");
  await testCtx.assert.throwNoException("signup", async () => {
    await apiServerClient.user.signup(
      "Lasse",
      "lassesundjohansen@gmail.com",
      "stupid"
    );
  });

  const users = await apiserverAdminClient.util.find("User", {
    email: "lassesundjohansen@gmail.com",
  });

  testCtx.assert.isArrayAndHasElements("Users", users);

  await testCtx.assert.throwNoException("verify email", async () => {
    await apiServerClient.user.verifyemail(users[0]._id, "stupid");
  });

  const areas = await apiServerClient.area.getAll();

  const categories = await apiServerClient.category.getAll();

  const add = await testCtx.assert.throwNoException("Create add", async () => {
    const add = await apiServerClient.add.create(
      "Hello",
      areas[0]._id,
      imageFile,
      categories[0]._id,
      "#ffffff",
      "test"
    );
    return add;
  });

  testCtx.assert.isObject("Add", add);

  await testCtx.assert.throwNoException("Accept", async () => {
    await apiServerClientAsOwner.add.accept(add._id);
  });

  await testCtx.assert.throwException("addTime", async () => {
    await apiServerClientAsOwner.add.addTime(add._id, "test");
  });
};

const performTests = async (testCtx, imageFile) => {
  const testFunctions = [
    // disallowSigninWithoutVerifyAccount,
    // allowSigninAfterSignupAndVerify,
    // disallowSignup2AccountsWithSameEmail,
    // disallowCreateAddIfNotSignin,
    // disallowVerifyAfterCreateAddWithoutPayment,
    // allowVerifyAfterCreateAddWithPayment,
    // disallowAddtimeAfterCreateAddWithoutPayment,
    allowAddtimeAfterVerify,
    //disallowCreate2OrdersWithSamePromo,
  ];

  for (var testFunction of testFunctions) {
    console.log(testFunction.toString());
    await apiserverAdminClient.util.deleteMany("User", {
      email: "lassesundjohansen@gmail.com",
    });
    await apiserverAdminClient.util.deleteMany("Add", {});
    await apiserverAdminClient.util.deleteMany("AddEvent", {});

    await testFunction(testCtx, imageFile);
  }
};

export default performTests;
