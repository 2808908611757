import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import logo from "../../../images/Logo Mandame Inbox 01.PNG";
import entrar from "../../../images/Entrar_2.png";

import { useUserContext } from "../../contexts/user.context";
import { useExceptionContext } from "../../contexts/exception.context";
import { useMessageContext } from "../../contexts/message.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { showSuccess } = useMessageContext();
  const { verifyEmail } = useUserContext();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const [isWorking, setIsWorking] = useState(false);

  const handleVerifyEmailClicked = async () => {
    try {
      setIsWorking(true);
      await verifyEmail(id, password);
      showSuccess("Correo verificado");
      navigate("/miembros/anuncios");
    } catch (ex) {
      handleException(ex);
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!password) return false;
    if (password.length < 3) return false;

    return true;
  };

  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <div className="row">
        <img src={logo} alt="" />
      </div>
      <div className="row">
        <h1 style={{ color: "#ff5f29", fontWeight: "bold" }}>
          Verifica tu correo
        </h1>
        <div style={{ fontWeight: "bold", marginTop: 30 }}>
          Contraseña (con lo que te registraste):
        </div>
        <div>
          <input
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          className="mybtn btn"
          onClick={handleVerifyEmailClicked}
          disabled={!isFormOk() || isWorking}
          style={{ marginTop: 30 }}
        >
          <img src={entrar} className="btnImg80" />
        </button>
      </div>
    </div>
  );
};

export default Page;
