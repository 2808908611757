import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";
import validateUtil from "../../../../utils/validate.util";

const Page = () => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const navigate = useNavigate();
  const { promotionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [promotion, setPromotion] = useState();
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(DateTime.now().toISODate());
  const [endDate, setEndDate] = useState(
    DateTime.now().plus({ month: 1 }).toISODate()
  );
  const [price, setPrice] = useState("0");
  const [months, setMonths] = useState("1");
  const [maxPromotionsPerAdd, setMaxPromotionsPerAdd] = useState("1");
  const [maxPromotionsPerUser, setMaxPromotionsPerUser] = useState("1");
  const [hidden, setHidden] = useState("0");

  const refresh = async () => {
    if (!promotionId) {
      setLoading(false);
      return;
    }

    try {
      const promotion = await apiServerClient.admin.promotion.getById(
        promotionId
      );
      setPromotion(promotion);
      setDescription(promotion.description);
      setStartDate(promotion.startDate);
      setEndDate(promotion.endDate);
      setHidden(promotion.hidden ? "1" : "0");
      setPrice(promotion.price.toString());
      setMonths(promotion.months.toString());
      setMaxPromotionsPerAdd(promotion.maxPromotionsPerAdd.toString());
      setMaxPromotionsPerUser(promotion.maxPromotionsPerUser.toString());
    } catch (ex) {
      handleException(ex);
    }
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const isFormOk = () => {
    if (!validateUtil.isStringWithContent(description)) return false;
    // if (promotion) {
    //   if (!promotion.default && !validateUtil.isStringWithContent(code))
    //     return false;
    // }
    if (startDate >= endDate) return false;

    return true;
  };

  const hasFormChanged = () => {
    if (!promotion) return false;
    if (promotion.startDate !== startDate) return true;
    if (promotion.hidden !== (hidden === "1")) return true;
    if (promotion.endDate !== endDate) return true;
    if (promotion.description !== description) return true;
    if (promotion.maxPromotionsPerAdd.toString() !== maxPromotionsPerAdd)
      return true;
    if (promotion.maxPromotionsPerUser.toString() !== maxPromotionsPerUser)
      return true;
    if (promotion.price.toString() !== price) return true;
    if (promotion.months.toString() !== months) return true;

    return false;
  };

  const onDeleteClicked = async () => {
    try {
      await apiServerClient.admin.promotion.delete(promotionId);
      showSuccess("Promocion borrado");
      navigate("./..");
    } catch (ex) {
      handleException(ex);
    }
  };

  const onCreateClicked = async () => {
    if (promotion?.default) {
      const wantToContinue = window.confirm(
        "Seguro que quieres cambiar el promo original?"
      );

      if (!wantToContinue) return;
    }

    try {
      await apiServerClient.admin.promotion.create(
        description,
        startDate,
        endDate,
        parseInt(months),
        parseInt(price),
        parseInt(maxPromotionsPerAdd),
        parseInt(maxPromotionsPerUser),
        hidden === "1"
      );
      showSuccess("Promocion creado");
      navigate("./..");
    } catch (ex) {
      handleException(ex);
    }
  };

  const onChangeClicked = async () => {
    if (promotion?.default) {
      const wantToContinue = window.confirm(
        "Seguro que quieres cambiar el promo original?"
      );

      if (!wantToContinue) return;
    }

    try {
      await apiServerClient.admin.promotion.update(
        promotionId,
        description,
        startDate,
        endDate,
        parseInt(months),
        parseInt(price),
        parseInt(maxPromotionsPerAdd),
        parseInt(maxPromotionsPerUser),
        hidden === "1"
      );
      showSuccess("Promocion actualizado");
      navigate("./..");
    } catch (ex) {
      handleException(ex);
    }
  };

  return (
    <div className="container">
      <div className="header1">Promocion</div>
      <div className="form-group mb-2">
        <label className="form-label">Descripcion</label>
        <div className="input-group">
          <input
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {promotion?.default && (
            <span class="input-group-text">principal</span>
          )}
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Inicio</label>
        <div>
          <input
            type={"date"}
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Termina</label>
        <div>
          <input
            type={"date"}
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Meses</label>
        <div>
          <input
            type="number"
            min={1}
            className="form-control"
            value={months}
            onChange={(e) => setMonths(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Max uso x anuncio</label>
        <div>
          <input
            type="number"
            min={1}
            className="form-control"
            value={maxPromotionsPerAdd}
            onChange={(e) => setMaxPromotionsPerAdd(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">Max uso x usuario</label>
        <div>
          <input
            type="number"
            min={1}
            className="form-control"
            value={maxPromotionsPerUser}
            onChange={(e) => setMaxPromotionsPerUser(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Precio</label>
        <div>
          <input
            type="number"
            min={1}
            className="form-control"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group mb-2">
        <label className="form-label">Ocultar</label>
        <div>
          <select
            className="form-select"
            value={hidden}
            onChange={(e) => setHidden(e.target.value)}
          >
            <option value="0">No</option>
            <option value="1">Si</option>
          </select>
        </div>
      </div>
      <div className="form-group mb-2">
        {!promotionId && (
          <button
            onClick={onCreateClicked}
            disabled={!isFormOk()}
            className="btn btn-primary"
          >
            Crear
          </button>
        )}
        {promotion && (
          <button
            onClick={onChangeClicked}
            disabled={!isFormOk() || !hasFormChanged()}
            className="btn btn-primary"
          >
            Actualizar
          </button>
        )}
        {promotion && !promotion.default && (
          <button
            onClick={onDeleteClicked}
            disabled={!isFormOk()}
            className="btn btn-danger"
          >
            Borrar
          </button>
        )}
      </div>
    </div>
  );
};

export default Page;
