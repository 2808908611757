import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

const Page = () => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const navigate = useNavigate();
  const { areaId } = useParams();
  const [buttonFile, setButtonFile] = useState();
  const [coverFile, setCoverFile] = useState();
  const [area, setArea] = useState();

  const refresh = async () => {
    try {
      const area = await apiServerClient.admin.area.getById(areaId);

      setArea(area);
      setButtonFile(area.buttonFile);
      setCoverFile(area.coverFile);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const isFormOk = () => {
    if (!buttonFile) return false;

    return true;
  };

  const onUpdateClicked = async () => {
    try {
      await apiServerClient.admin.area.update(areaId, buttonFile, coverFile);
      showSuccess("Area actualizado");
      navigate("./..");
    } catch (ex) {
      handleException(ex);
    }
  };

  if (!area) return null;

  return (
    <div className="container">
      <div className="header1">Area</div>
      <div className="form-group mb-2">
        <label className="form-label">Nombre</label>
        <div>{area.name}</div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">Boton</label>

        <div className="input-group">
          <input
            className="form-control"
            value={buttonFile?.name || ""}
            disabled={true}
          ></input>
          <label className="btn btn-primary">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                e.target.files.length && setButtonFile(e.target.files[0])
              }
              accept=".png"
            />
            Escoger
          </label>
        </div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">Portada</label>

        <div className="input-group">
          <input
            className="form-control"
            value={coverFile?.name || ""}
            disabled={true}
          ></input>
          <label className="btn btn-primary">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                e.target.files.length && setCoverFile(e.target.files[0])
              }
              accept=".png"
            />
            Escoger
          </label>
        </div>
      </div>

      <div className="form-group mb-2">
        <button
          onClick={onUpdateClicked}
          disabled={!isFormOk()}
          className="btn btn-primary"
        >
          Actualizar
        </button>
      </div>
    </div>
  );
};

export default Page;
