import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useUserContext } from "../../../contexts/user.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useNavigate, useParams } from "react-router-dom";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const { addId } = useParams();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const events = await apiServerClient.admin.addEvents.getByAddId(addId);

      setEvents(events);
    } catch (ex) {
      handleException(ex);
    }
  };

  const handleAddClicked = () => {
    navigate("../anuncio");
  };

  const handleEventsClicked = () => {
    navigate("../eventos");
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div className="container">
      <div className="header1">Anuncio eventos</div>
      <div>
        <button onClick={handleAddClicked} className="btn btn-secondary">
          Anuncio
        </button>
        <button
          onClick={handleEventsClicked}
          className="btn btn-secondary  ms-1"
        >
          Eventos
        </button>
      </div>
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <td>Fecha</td>
              <td>Tipo</td>
              <td>Datos</td>
            </tr>
          </thead>
          <tbody>
            {events.map((e) => (
              <tr>
                <td>{DateTime.fromJSDate(e.createdAt).toISODate()}</td>
                <td>{e.type}</td>
                <td>
                  <pre>{JSON.stringify(e.data, null, 4)}</pre>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Page;
