import { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import addUtil from "../../utils/add.util";
import { useExceptionContext } from "../contexts/exception.context";
import { useUserContext } from "../contexts/user.context";
import validateUtil from "../../utils/validate.util";
import { useMessageContext } from "../contexts/message.context";

const getVersion = (version, name, showImage) => {
  if (!version) return null;

  return (
    <div>
      <div>
        <strong>Tipo: </strong>
        {name}
      </div>
      <img
        src={version?.imageFile?.url}
        style={{
          width: "200px",
          height: "200px",
          objectFit: "contain",
          cursor: "pointer",
        }}
        onClick={() => showImage(version)}
      />
      {version.note.trim().length > 0 && (
        <div>
          <div>
            <strong>Nota: </strong>
            {version.note.split("\n").map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <div>
            <strong>Color de fondo: </strong>
            <span
              style={{
                width: 30,
                border: "1px solid black",
                backgroundColor: version.backgroundColor,
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
        </div>
      )}
      <div>
        <strong>Categoría: </strong>
        {version.category}
      </div>
      <div>
        <strong>Áreas: </strong>
        {version.areas.join(", ")}
      </div>
    </div>
  );
};

export default ({ add, onUpdate }) => {
  const { apiServerClient } = useUserContext();
  const [showChangeDaysModal, setShowChangeDaysModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [version, setVersion] = useState();
  const [showRegisterPaymentModal, setShowRegisterPaymentModal] =
    useState(false);
  const [days, setDays] = useState(30);
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState("0");
  const { showSuccess } = useMessageContext();

  const { handleException } = useExceptionContext();

  const showImage = (version) => {
    setVersion(version);
    setShowImageModal(true);
  };

  const handleConfirmAddDaysClicked = async () => {
    try {
      await apiServerClient.admin.add.addTime(add._id, days);
      setShowChangeDaysModal(false);
      if (onUpdate) onUpdate();
    } catch (ex) {
      handleException(ex);
    }
  };

  const handleChangeDaysClicked = async () => {
    setShowChangeDaysModal(true);
  };

  const handleRegisterPaymentClicked = async () => {
    setShowRegisterPaymentModal(true);
  };

  const handleConfirmRegisterPaymentClicked = async () => {
    try {
      await apiServerClient.admin.add.registerPayment(
        add._id,
        amount,
        reference
      );
      setShowRegisterPaymentModal(false);
      showSuccess("Pago registrado");
      if (onUpdate) onUpdate();
    } catch (ex) {
      handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      if (!window.confirm("Seguro que quieres borrar anuncio?")) return;

      await apiServerClient.admin.add.delete(add._id);
      if (onUpdate) onUpdate();
    } catch (ex) {
      handleException(ex);
    }
  };

  const isAddDaysFormOk = () => {
    if (!validateUtil.isNumber2(days, -365, 365)) return false;

    return true;
  };

  const isRegisterPaymentFormOk = () => {
    if (!validateUtil.isNumber2(amount, 1, 10000)) return false;
    if (!validateUtil.isStringWithContent(reference)) return false;

    return true;
  };

  const isAmountNegative = () => {
    const i = parseInt(days);

    if (i === NaN) {
      return false;
    }

    return i < 0;
  };

  return (
    <div className="add">
      <div>
        <strong>Id: </strong>
        {add.id}
      </div>
      {add.endDate && (
        <div>
          <strong>Periodo: </strong>
          {add.startDate} - {add.endDate}
        </div>
      )}
      <div>
        <strong>Estatus: </strong>
        {addUtil.getStatus(add)}
      </div>
      <Tabs
        defaultActiveKey={add.currentVersion ? "current" : "new"}
        className="mb-3"
      >
        {add.currentVersion && (
          <Tab eventKey="current" title="Anuncio Vigente">
            {getVersion(add.currentVersion, "Anuncio Vigente", showImage)}
          </Tab>
        )}
        {add.reviewVersion && (
          <Tab eventKey="new" title="Nuevo Anuncio">
            {getVersion(add.reviewVersion, "Nuevo Anuncio", showImage)}
          </Tab>
        )}
      </Tabs>
      <div>
        {add.status.isPaymentPending && (
          <button
            className="btn btn-primary me-1"
            onClick={handleRegisterPaymentClicked}
          >
            Registrar pago
          </button>
        )}
        {add.status.canAddTime && (
          <button
            className="btn btn-primary me-1"
            onClick={handleChangeDaysClicked}
          >
            Agregar/quitar dias
          </button>
        )}
        <button className="btn btn-danger" onClick={handleDeleteClicked}>
          Borrar
        </button>
      </div>

      <Modal
        show={showRegisterPaymentModal}
        onHide={() => setShowRegisterPaymentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Registrar pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="form-label">Referencia</label>
            <input
              className="form-control"
              value={reference}
              type="text"
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
          <div>
            <label className="form-label">Cantidad</label>
            <input
              className="form-control"
              value={amount}
              type="number"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowRegisterPaymentModal(false)}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary"
            onClick={handleConfirmRegisterPaymentClicked}
            disabled={!isRegisterPaymentFormOk()}
          >
            Registrar pago
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showImageModal}
        fullscreen
        onHide={() => setShowImageModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Anuncio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img src={version?.imageFile?.url} style={{ width: "100%" }} />
          </div>
          <div>
            <label className="form-label">Cantidad</label>
            <input
              className="form-control"
              value={amount}
              type="number"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowRegisterPaymentModal(false)}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary"
            onClick={handleConfirmRegisterPaymentClicked}
            disabled={!isRegisterPaymentFormOk()}
          >
            Registrar pago
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showChangeDaysModal}
        onHide={() => setShowChangeDaysModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cuantos dias quieres agregar/quitar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="form-label">Dias</label>
            <input
              className="form-control"
              value={days}
              type="number"
              onChange={(e) => setDays(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowChangeDaysModal(false)}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary"
            onClick={handleConfirmAddDaysClicked}
            disabled={!isAddDaysFormOk()}
          >
            {isAmountNegative() ? "Quitar dias" : "Agregar dias"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
