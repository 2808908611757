import {
  Routes,
  Route,
  useLocation,
  Outlet,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { useUserContext } from "../../contexts/user.context";

import logo from "../../../images/logo.png";
import MainPage from "./main.page";
import MagazinesPage from "./magazines.page";
import MagazinePage from "./magazine.page";
import AboutPage from "./about.page";
import PromosPage from "./promos.page";
import DesignPage from "./design.page";
import VerifyEmailPage from "./verifyEmail.page";

import MembersPage from "./members.page";
import SigninPage from "./signin.page";
import SignupPage from "./signup.page";
import LostPasswordPage from "./members/lostPassword.page";

import MembersProfilePage from "./members/profile.page";
import MembersAddsPage from "./members/adds.page";
import MembersAddPage from "./members/add.page";
import MembersDeleteAddPage from "./members/deleteAdd.page";
import MembersCreateOrderPage from "./members/createOrder.page";

import AdminUsersPage from "./admin/users.page";
import AdminUserPage from "./admin/user.page";
import AdminUserAddPage from "./admin/userAdd.page";
import AdminUserAddEventsPage from "./admin/userAddEvents.page";
import AdminReviewsPage from "./admin/reviews.page";
import AdminReviewPage from "./admin/review.page";

import AdminFilesPage from "./admin/files.page";
import AdminPromotionPage from "./admin/promotion.page";
import AdminPromotionsPage from "./admin/promotions.page";
import AdminCategoriesPage from "./admin/categories.page";
import AdminCategoryPage from "./admin/category.page";
import AdminAreasPage from "./admin/areas.page";
import AdminAreaPage from "./admin/area.page";
import AdminMagazinePage from "./admin/magazine.page";

import settings from "../../../utils/settings";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, signOut } = useUserContext();

  const menuitems = [
    {
      name: "Inicio",
      path: "/",
      selectedHandler: (currentpath) => currentpath === "/",
      roles: ["Visitor"],
    },
    {
      name: "Registrarse",
      path: "/registrarse",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/registrarse") ||
        currentpath.startsWith("/miembros/usuario/registrarme"),
      roles: ["Visitor"],
    },
    {
      name: "Acceso a miembros",
      path: "/miembros/usuario/ingresar",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/miembros") &&
        !currentpath.startsWith("/miembros/usuario/registrarme"),
      roles: ["Visitor"],
    },
    {
      name: "Mis anuncios",
      path: "/miembros/anuncios",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/miembros/anuncios"),
      roles: ["Customer"],
    },
    {
      name: "Revista",
      path: "/revistas",
      selectedHandler: (currentpath) => currentpath.startsWith("/revistas"),
      roles: ["Visitor"],
    },
    {
      name: "Usuarios",
      path: "/admin/usuarios",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/admin/usuarios"),
      roles: ["Admin"],
    },
    {
      name: "Revisiones",
      path: "/admin/revisiones",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/admin/revisiones"),
      roles: ["Admin"],
    },
    {
      name: "Areas",
      path: "/admin/areas",
      selectedHandler: (currentpath) => currentpath.startsWith("/admin/areas"),
      roles: ["Admin"],
    },
    {
      name: "Categorias",
      path: "/admin/categorias",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/admin/categorias"),
      roles: ["Admin"],
    },
    {
      name: "Promociones",
      path: "/admin/promociones",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/admin/promociones"),
      roles: ["Admin"],
    },
    {
      name: "Revista",
      path: "/admin/revista",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/admin/revista"),
      roles: ["Admin"],
    },
    {
      name: "Archivos",
      path: "/admin/archivos",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/admin/archivos"),
      roles: ["Admin"],
    },
    {
      name: "Perfil",
      path: "/miembros/perfil",
      selectedHandler: (currentpath) =>
        currentpath.startsWith("/miembros/perfil"),
      roles: ["Customer", "Admin"],
    },
    {
      name: "Salir",
      onClick: () => {
        signOut();
        navigate("/");
      },
      roles: ["Admin", "Customer"],
    },
  ];

  const onLinkItemClicked = (item) => {
    console.log(item);
    if (item.onClick) {
      item.onClick();
    } else if (item.path) {
      navigate(item.path);
    }
  };

  if (!user) return null;

  return (
    <div>
      <Navbar expand="lg" variant="dark">
        <Container>
          <Navbar.Brand
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={logo} alt={settings.company.name} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            {menuitems.map((i) => {
              if (i.roles && !i.roles.some((r) => user.roles.includes(r)))
                return null;

              return (
                <Nav.Link
                  className={`${
                    i.selectedHandler && i.selectedHandler(location.pathname)
                      ? "selected"
                      : ""
                  }`}
                  key={i.name}
                  onClick={() => onLinkItemClicked(i)}
                >
                  {i.name}
                </Nav.Link>
              );
            })}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div style={{ minHeight: "400px", paddingTop: 30, paddingBottom: 30 }}>
        <Outlet />
      </div>
      <div
        className="navbar"
        style={{ textAlign: "center", color: "white", display: "inherit" }}
      >
        Comunícate con nosotros:{" "}
        <a href={`mailto:${settings.company.email}`} className="link2">
          {settings.company.email}
        </a>
      </div>
    </div>
  );
};

const ProtectedRoute = ({ children }) => {
  const { user } = useUserContext();

  if (!user?._id) {
    return <Navigate to="/miembros/usuario/ingresar" replace />;
  }

  return children ? children : <Outlet />;
};

const MainNav = () => {
  return (
    <Routes>
      <Route path="" element={<NavBar />}>
        <Route index element={<MainPage />}></Route>
        <Route path="registrarse" element={<AboutPage />} />
        <Route path="diseno" element={<DesignPage />} />
        <Route path="promos" element={<PromosPage />} />
        <Route path="revistas" element={<MagazinesPage />} />
        <Route path="revistas/:name" element={<MagazinePage />} />
        <Route path="miembros">
          <Route path="usuario">
            <Route path="registrarme" element={<SignupPage />} />
            <Route path="ingresar" element={<SigninPage />} />
            <Route path="recuperarcontrasena" element={<LostPasswordPage />} />
          </Route>
          <Route path="verificar/:id" element={<VerifyEmailPage />} />
          <Route path="anuncios" element={<ProtectedRoute />}>
            <Route index element={<MembersAddsPage />} />
            <Route path="nuevo" element={<MembersAddPage />} />
            <Route path=":addId">
              <Route path="editar" element={<MembersAddPage />} />
              <Route path="borrar" element={<MembersDeleteAddPage />} />
              <Route path="mastiempo" element={<MembersCreateOrderPage />} />
            </Route>
          </Route>
          <Route
            path="perfil"
            element={
              <ProtectedRoute>
                <MembersProfilePage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="admin" element={<ProtectedRoute />}>
          <Route path="usuarios">
            <Route index element={<AdminUsersPage />} />
            <Route path=":userId">
              <Route index element={<AdminUserPage />} />
              <Route path=":addId">
                <Route path="anuncio" element={<AdminUserAddPage />} />
                <Route path="eventos" element={<AdminUserAddEventsPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="archivos" element={<AdminFilesPage />} />
          <Route path="revisiones">
            <Route index element={<AdminReviewsPage />} />
            <Route path=":addId" element={<AdminReviewPage />} />
          </Route>
          {/* <Route path="lanzamientos" element={<AdminReleasesPage />} /> */}
          <Route path="promociones">
            <Route index element={<AdminPromotionsPage />} />
            <Route path="nuevo" element={<AdminPromotionPage />} />
            <Route path=":promotionId" element={<AdminPromotionPage />} />
          </Route>
          <Route path="categorias">
            <Route index element={<AdminCategoriesPage />} />
            <Route path=":categoryId" element={<AdminCategoryPage />} />
          </Route>
          <Route path="areas">
            <Route index element={<AdminAreasPage />} />
            <Route path=":areaId" element={<AdminAreaPage />} />
          </Route>
          <Route path="revista">
            <Route index element={<AdminMagazinePage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default MainNav;
