import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import mandameinbox from "../../../../images/Logo Mandame Inbox 01.PNG";
import nuevoAnuncio from "../../../../images/Subir Anuncio_2.png";
import changeAnuncio from "../../../../images/Cambiar_2.png";
import paymentInstructions from "../../../../images/Boleta de Pago.PNG";
import borrarAnuncio from "../../../../images/Borrar.PNG";
import moretime from "../../../../images/MasTiempo.PNG";

import { useUserContext } from "../../../contexts/user.context";
import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import addUtil from "../../../../utils/add.util";

const AddsPage = () => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();

  const { user, apiServerClient } = useUserContext();
  const [adds, setAdds] = useState([]);

  const refresh = async () => {
    if (!user) return;

    try {
      const adds = await apiServerClient.customer.add.getAll();

      setAdds(adds);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, [user]);

  const getVersion = (version, name) => {
    if (!version) return null;

    return (
      <div
        className="col-sm"
        style={{
          backgroundColor: name === "Anuncio Vigente" ? "white" : "#f9f9f9",
        }}
      >
        <img
          src={version.imageFile.url}
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
        />
        {version.note.trim().length > 0 && (
          <div>
            <div>
              <strong>Nota: </strong>
              {version.note.split("\n").map((line, i) => (
                <span key={i}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
            <div>
              <strong>Color de fondo: </strong>
              <span
                style={{
                  width: 30,
                  border: "1px solid black",
                  backgroundColor: version.backgroundColor,
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </div>
        )}
        <div>
          <strong>Categoría: </strong>
          {version.category}
        </div>
        <div>
          <strong>Áreas: </strong>
          {version.areas.join(", ")}
        </div>
        {version.rejectReason && (
          <div>
            <strong>Razón de rechazado: </strong>
            {version.rejectReason}
          </div>
        )}
      </div>
    );
  };

  const list = adds.map((add) => {
    const helpLines = addUtil.getStatusHelp(add);
    return (
      <div className="add" key={add._id}>
        <div>
          <strong>Id: </strong>
          {add.id}
        </div>
        {add.endDate && (
          <div>
            <strong>Periodo: </strong>
            {add.startDate} - {add.endDate}
          </div>
        )}
        <div>
          <strong>Estatus: </strong>
          {addUtil.getStatus(add)}
          {helpLines.length > 0 && (
            <div className="alert alert-warning">
              <ul>
                {helpLines.map((l) => (
                  <li key={l}>{l}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <Tabs
          defaultActiveKey={add.currentVersion ? "current" : "new"}
          className="mb-3"
        >
          {add.currentVersion && (
            <Tab eventKey="current" title="Anuncio Vigente">
              {getVersion(add.currentVersion, "Anuncio Vigente")}
            </Tab>
          )}
          {add.reviewVersion && (
            <Tab eventKey="new" title="Nuevo Anuncio">
              {getVersion(add.reviewVersion, "Nuevo Anuncio")}
            </Tab>
          )}
        </Tabs>
        <div style={{ marginTop: 30 }}>
          <Link to={`./${add._id}/editar`} className="mybtn btn">
            <img src={changeAnuncio} alt="" className="img-fluid btnImg80" />
          </Link>
          {add.status.isPaymentPending && (
            <button
              onClick={() => handleSendPayInfoClicked(add._id)}
              className="mybtn btn"
            >
              <img
                src={paymentInstructions}
                alt=""
                className="img-fluid btnImg80"
              />
            </button>
          )}
          {/* {!add.status.isPaymentPending && !add.status.isReviewPending && ( */}
          {add.status.canAddTime && (
            <Link to={`./${add._id}/mastiempo`} className="mybtn btn">
              <img src={moretime} alt="" className="img-fluid btnImg80" />
            </Link>
          )}
          <Link to={`./${add._id}/borrar`} className="mybtn btn">
            <img src={borrarAnuncio} alt="" className="img-fluid btnImg80" />
          </Link>
        </div>
      </div>
    );
  });

  const handleSendPayInfoClicked = async (addId) => {
    try {
      await apiServerClient.customer.add.sendPaymentInstructions(addId);
      showSuccess("Se envio 'como pagar' a tu correo");
    } catch (ex) {
      handleException(ex);
    }
  };

  if (!user) return null;

  return (
    <div className="container centerApp">
      <div>
        <img
          src={mandameinbox}
          alt=""
          className="img-fluid"
          style={{ maxHeight: 150 }}
        />
      </div>
      <Link to="nuevo" className="mybtn btn">
        <img
          src={nuevoAnuncio}
          alt=""
          className="img-fluid btnImg80"
          style={{ marginTop: 40 }}
        />
      </Link>
      <h1 style={{ color: "#ff5f29", marginTop: 20, marginBottom: 40 }}>
        Mis anuncios
      </h1>

      {list.length === 0 ? (
        <div style={{ fontStyle: "italic" }}>
          No existe ningún anuncio tuyo, da click arriba para agregar uno
        </div>
      ) : (
        <div className="adds">{list}</div>
      )}
    </div>
  );
};

export default AddsPage;
