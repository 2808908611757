import React, { useState } from "react";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";
import actualizar from "../../../../images/Actualizar_2.png";
import { useMessageContext } from "../../../contexts/message.context";

export default () => {
  const { user, update } = useUserContext();
  const { handleException } = useExceptionContext();
  const { showSuccess } = useMessageContext();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [newPassword, setNewPassword] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await update(name, newPassword ? password1 : "");
      showSuccess("Perfil actualizado");
    } catch (ex) {
      handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const hasFormChanged = () => {
    if (newPassword) return true;
    if (name != user.name) return true;
    return false;
  };

  const isFormOk = () => {
    if (newPassword) {
      if (!password1) return false;
      if (!password2) return false;
      if (password1 != password2) return false;
    }
    if (!name) return false;

    return true;
  };

  return (
    <div className="container centerApp">
      <h1 className="header1">Perfil</h1>
      <div className="form-outline form-white mb-2">
        <label className="form-label">
          Nombre: (con que estas registrado aquí)
        </label>
        <div>
          <input
            className="form-control"
            value={name}
            disabled={isWorking}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="form-outline form-white mb-2">
        <label className="form-label">Correo</label>
        <div>
          <input
            className="form-control"
            value={email}
            disabled={true}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="form-outline form-white mb-2">
        <label className="form-label">Nueva contraseña</label>
        <div>
          <input
            className="form-check-label"
            type="checkbox"
            value={newPassword}
            disabled={isWorking}
            onChange={(e) => setNewPassword(e.target.checked)}
          />
        </div>
      </div>
      {newPassword && (
        <div>
          <div className="form-outline form-white mb-2">
            <label className="form-label">Escribe nueva contraseña</label>
            <div>
              <input
                className="form-control"
                type="password"
                value={password1}
                disabled={isWorking}
                onChange={(e) => setPassword1(e.target.value)}
              />
            </div>
          </div>
          <div className="form-outline form-white mb-2">
            <label className="form-label">
              Escribe nuevamente nueva contraseña
            </label>
            <div>
              <input
                className="form-control"
                type="password"
                value={password2}
                disabled={isWorking}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}

      <button
        className="mybtn btn"
        onClick={handleUpdateClicked}
        disabled={!isFormOk() || !hasFormChanged() || isWorking}
        style={{ marginTop: 30 }}
      >
        <img src={actualizar} className="btnImg80" />
      </button>
    </div>
  );
};
