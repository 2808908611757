import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import { useExceptionContext } from "../../contexts/exception.context";
import { useUserContext } from "../../contexts/user.context";
import anunciateYa from "../../../images/Anunciate YA OK.PNG";
import registerMe from "../../../images/Boton Registrarme.PNG";
import designHelp from "../../../images/Boton Apoyo Diseno.PNG";
import iAmMember from "../../../images/Boton Soy Miembro.PNG";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [promotions, setPromotions] = useState([]);

  const refresh = async () => {
    try {
      const promotions = await apiServerClient.visitor.promotion.getAll();

      setPromotions(promotions);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div>
      <div className="container text-center">
        <h1 className="header1">PROMOCIONES</h1>
      </div>
      <div className="container mt-5" style={{ maxWidth: 600 }}>
        {promotions.map((p, index) => {
          return (
            <div
              className={`row ${index > 0 ? "mt-4" : ""} p-3`}
              style={{
                borderRadius: 5,
                color: "black",
                backgroundColor: "white",
                border: "1px solid #ff5f29",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  className="col-6"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 20, fontWeight: "bold" }}>
                    {p.description}
                  </div>
                  <div style={{ fontSize: 14 }}>
                    Termina:{" "}
                    {
                      DateTime.fromISO(p.endDate).toLocaleString(
                        DateTime.DATE_FULL,
                        { locale: "es-mx" }
                      )
                      // .toLocaleString({
                      //   locale: "es",
                      //   ...DateTime.DATE_FULL,
                      // })
                    }
                  </div>
                </div>
                <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ fontSize: 26, fontWeight: "bold", flex: 1 }}
                >
                  ${p.price} pesos
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="container text-center" style={{ marginTop: 40 }}>
        <Link to="/miembros/usuario/registrarme" className="mybtn btn">
          <img src={registerMe} className="img-fluid btnImg80" alt="" />
        </Link>
      </div>
      <div className="container text-center">
        <Link to="/miembros/usuario/ingresar" className="mybtn btn">
          <img src={iAmMember} className="img-fluid btnImg80" alt="" />
        </Link>
      </div>
      <div className="container text-center">
        <Link to="/diseno" className="mybtn btn">
          <img src={designHelp} className="img-fluid btnImg80" alt="" />
        </Link>
      </div>
      {/*       
      <div className="container text-center">
        <Link to="/miembros/usuario/registrarme" className="mybtn btn">
          <img
            src={anunciateYa}
            className="img-fluid btnImg80"
            alt=""
            style={{ marginTop: 40 }}
          />
        </Link>
      </div> */}
    </div>
  );
};

export default Page;
