import { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import logo from "../../../images/Logo Mandame Inbox 01.PNG";
import entrar from "../../../images/Entrar.PNG";

import { useUserContext } from "../../contexts/user.context";
import validateUtil from "../../../utils/validate.util";
import { useExceptionContext } from "../../contexts/exception.context";
import { useMessageContext } from "../../contexts/message.context";

const Page = ({}) => {
  const { signinByCredentials } = useUserContext();
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const pwRef = useRef();
  const [isWorking, setIsWorking] = useState(false);

  const handleSigninClicked = async () => {
    try {
      setIsWorking(true);
      const auth = await signinByCredentials(email, password);
      if (auth.user.roles.includes("Admin")) {
        navigate("/admin/revisiones");
      } else if (auth.user.roles.includes("Customer")) {
        navigate("/miembros/anuncios");
      } else {
        navigate("/");
      }

      showSuccess("Ingresado con exito");
    } catch (ex) {
      handleException(ex);
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!password) return false;
    if (!email) return false;
    if (!validateUtil.isEmail(email)) return false;

    return true;
  };

  const togglePassword = () => {
    if (!pwRef.current) return;
    if (pwRef.current.type === "password") {
      pwRef.current.type = "text";
    } else if (pwRef.current.type === "text") {
      pwRef.current.type = "password";
    }
  };

  const handleKeydown = async (e) => {
    if (e.which === 13) {
      e.preventDefault();

      if (!isFormOk()) return;

      await handleSigninClicked();
    }
  };

  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <div className="row">
        <img src={logo} alt="" />
      </div>
      <div className="row">
        <div className="header1">Soy Miembro</div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          E-mail:
        </div>
        <div>
          <input
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          Contraseña:
        </div>
        <div className="input-group">
          <input
            ref={pwRef}
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeydown}
          />
          <button className="btn btn-primary" onClick={togglePassword}>
            Ver
          </button>
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: 50,
          }}
        >
          <button
            onClick={handleSigninClicked}
            className="mybtn btn"
            disabled={!isFormOk() || isWorking}
          >
            <img
              src={entrar}
              alt=""
              className="btnImg80"
              style={{ marginTop: "30px" }}
            />
          </button>
          <div>
            <Link to="../registrarme" className="link1">
              No tengo cuenta, quiero registrarme
            </Link>
          </div>
          <div>
            <Link to="../recuperarcontrasena" className="link1">
              Recuperar contraseña
            </Link>
          </div>
        </div>
      </div>
    </div>

    // <div className="row" style={{ marginTop: 30 }}>
    //   <div className="col-sm">
    //     <img
    //       src={mandameinbox2}
    //       alt=""
    //       style={{ maxHeight: 300, maxWidth: "100%" }}
    //     />
    //   </div>
    //   <div className="col-sm">
    //     <div style={{ fontSize: 30, color: "#ff5f29", fontWeight: "bold" }}>
    //       Mi Cuenta
    //     </div>
    //     <div style={{ fontWeight: "bold" }}>E-mail:</div>
    //     <div>
    //       <input
    //         className="form-control"
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //     </div>
    //     <div style={{ fontWeight: "bold" }}>
    //       Contraseña: (con lo que deseas registrarte aquí)
    //     </div>
    //     <div>
    //       <input
    //         className="form-control"
    //         type="password"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //         onKeyDown={handleKeydown}
    //       />
    //     </div>
    //     <div
    //       style={{
    //         textAlign: "center",
    //         marginBottom: 50,
    //       }}
    //     >
    //       <button
    //         onClick={handleSigninClicked}
    //         className="mybtn btn"
    //         disabled={!isFormOk()}
    //       >
    //         <img
    //           src={entrar}
    //           alt=""
    //           className="btnImg80"
    //           style={{ marginTop: "30px" }}
    //         />
    //       </button>
    //       <div>
    //       </div>
    //
    //     </div>
    //   </div>
  );
};

export default Page;
