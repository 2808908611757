import React, { useState, useEffect } from "react";
import cookieUtil from "../../utils/cookie.util";
import ApiServerClient from "../../utils/apiserver.client";

const apiServerClient = new ApiServerClient();
const userContext = React.createContext();

export const useUserContext = () => {
  return React.useContext(userContext);
};

const notAuthenticatedUser = {
  name: "",
  _id: "",
  permissions: ["Home", "About", "Magazines", "Members"],
  roles: ["Visitor"],
};

export const UserContext = ({ children }) => {
  const [user, setUser] = useState(notAuthenticatedUser);
  const [loading, setLoading] = useState(true);

  const signinByToken = async () => {
    try {
      const token = cookieUtil.getItem("token");

      if (token) {
        const auth = await apiServerClient.user.signinByToken(token);

        setUser(auth.user);
        setToken(auth.token);
      }
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    signinByToken();
  }, []);

  const verifyEmail = async (id, password) => {
    const auth = await apiServerClient.user.verifyemail(id, password);
    setUser(auth.user);
    setToken(auth.token);
  };

  const signinByCredentials = async (email, password) => {
    const auth = await apiServerClient.user.signinByCredentials(
      email,
      password
    );
    setUser(auth.user);
    setToken(auth.token);
    return auth;
  };

  const setToken = (token, remember) => {
    if (token) {
      if (remember) {
        cookieUtil.setItem("token", token, Infinity, "/");
      } else {
        cookieUtil.setItem("token", token, null, "/");
      }
    } else {
      cookieUtil.removeItem("token", "/");
    }
  };

  const update = async (name, password) => {
    await apiServerClient.user.update(name, password);
    setUser({ ...user, name });
  };

  const setUserProxy = (user) => {
    if (!user) user = notAuthenticatedUser;
    setUser(user);
  };

  const signOut = () => {
    setUserProxy();
    setToken();
  };

  const redirectOnNotLoggedIn = () => {};

  if (loading) return null;

  const value = {
    user,
    signinByCredentials,
    signOut,
    verifyEmail,
    update,
    apiServerClient,
  };

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

//setUser: setUserProxy, setToken,
