import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

const Page = () => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [headerFile, setHeaderFile] = useState();
  const [category, setCategory] = useState();
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [w, setW] = useState("");
  const [h, setH] = useState("");
  const [number, setNumber] = useState("");

  const refresh = async () => {
    try {
      const category = await apiServerClient.admin.category.getById(categoryId);
      setCategory(category);
      setX((category.bounds?.x || "").toString());
      setY((category.bounds?.y || "").toString());
      setW((category.bounds?.w || "").toString());
      setH((category.bounds?.h || "").toString());
      setNumber((category.number || "").toString());
      setHeaderFile(category.headerFile);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const isFormOk = () => {
    //if (!headerFile) return false;
    if (!x) return false;
    if (!y) return false;
    if (!w) return false;
    if (!h) return false;
    if (!number) return false;
    console.log(number);
    return true;
  };

  const hasFormChanged = () => {
    return true;
  };

  const onChangeClicked = async () => {
    try {
      await apiServerClient.admin.category.update(
        categoryId,
        headerFile,
        x,
        y,
        w,
        h,
        number
      );
      showSuccess("Categoria actualizado");
      navigate("./..");
    } catch (ex) {
      handleException(ex);
    }
  };

  if (!category) return null;

  return (
    <div className="container">
      <div className="header1">Categoria</div>
      <div className="form-group mb-2">
        <label className="form-label">Nombre</label>
        <div>{category.name}</div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">Number</label>

        <input
          type="number"
          min="1"
          className="form-control"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </div>

      <div className="form-group mb-2">
        <label className="form-label">Encabezamiento</label>

        <div className="input-group">
          <input
            className="form-control"
            value={headerFile?.name || ""}
            disabled={true}
          ></input>
          <label className="btn btn-primary">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                e.target.files.length && setHeaderFile(e.target.files[0])
              }
              accept=".png"
            />
            Escoger
          </label>
        </div>
      </div>

      <div className="form-group mb-2">
        <label className="form-label">Indice</label>

        <div className="input-group">
          <span className="input-group-text">x</span>
          <input
            type="number"
            min="0"
            className="form-control"
            value={x}
            onChange={(e) => setX(e.target.value)}
          />
          <span className="input-group-text">y</span>
          <input
            type="number"
            min="0"
            className="form-control"
            value={y}
            onChange={(e) => setY(e.target.value)}
          />
          <span className="input-group-text">ancho</span>
          <input
            type="number"
            min="1"
            className="form-control"
            value={w}
            onChange={(e) => setW(e.target.value)}
          />
          <span className="input-group-text">altura</span>
          <input
            type="number"
            min="1"
            className="form-control"
            value={h}
            onChange={(e) => setH(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group mb-2">
        <button
          onClick={onChangeClicked}
          disabled={!isFormOk()}
          className="btn btn-primary"
        >
          Actualizar
        </button>
      </div>
    </div>
  );
};

export default Page;
