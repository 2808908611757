const settings = {
  company: {
    name: "Mándame inbox",
    www: "www.mandameinbox.com",
    email: "contacto@mandameinbox.com",
    facebook: "https://www.facebook.com/profile.php?id=100083277443991",
    whatsapp: "https://api.whatsapp.com/send?phone=528120157789",
  },
};

export default settings;
