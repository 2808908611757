const util = {
  isNumber: (text, digits) => {
    const s = new RegExp(`^\\d{${digits}}$`).test(text);
    return s;
  },
  isNumber2: (val, minValue, maxValue) => {
    const number = parseInt(val);
    const isNumber = !isNaN(number);

    if (!isNumber) return false;
    if (number < minValue) return false;
    if (number > maxValue) return false;

    return true;
  },
  isEmail: (email) => {
    return String(email)
      .toLowerCase()
      .trim()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  isWhatsapp: (phone) => {
    return String(phone)
      .toLowerCase()
      .trim()
      .match(/^\d{10}$/);
  },
  isStringWithContent: (str) => {
    if (!str) return false;
    if (!str.trim()) return false;

    return true;
  },
};

export default util;
