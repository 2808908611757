import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import hexRgb from "hex-rgb";
import { HexColorPicker } from "react-colorful";

//import next from "../../../../images/siguente_2.jpg";
import next from "../../../../images/next2.png";
import prev from "../../../../images/prev2.png";
import actualizar from "../../../../images/Actualizar_2.png";
import mandameinbox from "../../../../images/Logo Mandame Inbox 01.PNG";
import select from "../../../../images/Elegir Archivo_2.jpg";
import comprar from "../../../../images/Publicar Ahora2.png";
import paymentInstructions from "../../../../images/Boleta de Pago.PNG";

import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

const Page = () => {
  const { apiServerClient } = useUserContext();
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const navigate = useNavigate();
  const { addId } = useParams();
  const [areas, setAreas] = useState([]);
  const [categories, setCategories] = useState([]);
  const imageRef = useRef(new Image());
  const canvasRef = useRef();
  const [dummy, setDummy] = useState(1);
  const [add, setAdd] = useState();
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [areaIds, setAreaIds] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [note, setNote] = useState("");
  const [file, setFile] = useState();
  const [stepIndex, setStepIndex] = useState(0);
  const [promotions, setPromotions] = useState([]);
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const update = () => {
      updatePreview(note, backgroundColor);
    };
    window.addEventListener("resize", update);

    imageRef.current.onload = update;

    return () => {
      imageRef.current.onload = undefined;
      window.removeEventListener("resize", update);
    };
  }, []);

  window.onresize = () => {};

  const refresh = async () => {
    try {
      const promotions = await apiServerClient.customer.promotion.getByAddId(
        ""
      );
      const areas = await apiServerClient.customer.area.getAll();
      const categories = await apiServerClient.customer.category.getAll();

      if (addId) {
        // update
        const add = await apiServerClient.customer.add.getById(addId);
        const version = add.reviewVersion || add.currentVersion;
        imageRef.current.src = version.imageFile.url;
        setAreas(areas);
        setCategories(categories);

        setAdd(add);
        setNote(version.note);
        setCategoryId(categories.find((c) => c.name === version.category)._id);
        setAreaIds(
          areas.filter((c) => version.areas.includes(c.name)).map((a) => a._id)
        );
        setBackgroundColor(version.backgroundColor);
        // file?
      } else {
        // new
        setAreas(areas);
        setCategories(categories);

        setNote("");
        if (categories.length) setCategoryId(categories[0]._id);
        //if (areas.length) setAreaIds(areas[0]._id); // no selection
        setBackgroundColor("#ffffff");
      }
      setPromotions(promotions);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const onUpdateClicked = async (e) => {
    try {
      setIsWorking(true);
      await apiServerClient.customer.add.update(
        addId,
        note,
        areaIds,
        file,
        categoryId,
        backgroundColor
      );
      showSuccess("Anuncio actualizado");
      navigate("./../..");
    } catch (ex) {
      handleException(ex);
      setIsWorking(false);
    }
  };

  // https://www.npmjs.com/package/hex-rgb
  const colourIsLight = (r, g, b) => {
    // Counting the perceptive luminance
    // human eye favors green color...
    //var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    var a = 1 - (0.333 * r + 0.333 * g + 0.333 * b) / 255;
    return a < 0.5;
  };

  const convertToLines = (context, note, maxWidth) => {
    var result = [];
    var lines = note.split("\n");

    for (var l = 0; l < lines.length; l++) {
      var line = "";
      var words = lines[l].split(" ");
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + " ";
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          result.push(line);
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }
      result.push(line);
    }

    while (result.length > 0) {
      if (result[0].trim() === "") {
        result.shift();
      } else break;
    }

    while (result.length > 0) {
      if (result[result.length - 1].trim() === "") {
        result.pop();
      } else break;
    }

    return result;
  };

  const parseNote = (note) => {
    return note.substring(0, 250);
    // var lines = note.split("\n");
    // const lineMaxLength = 100;

    // if (lines.length > 6) lines.length = 6;
    // for (var i = 0; i < lines.length; i++) {
    //   if (lines[i].length > lineMaxLength)
    //     lines[i] = lines[i].substring(0, lineMaxLength);
    // }

    // return lines.join("\n");
  };

  const updatePreview = (note, backgroundColor) => {
    const ctx = canvasRef.current.getContext("2d");
    const imageAspectRatio = imageRef.current
      ? imageRef.current.height / imageRef.current.width
      : 1.5;
    const imageCanvasRatio = imageAspectRatio > 2 ? 2 : imageAspectRatio;
    const imageCanvasWidth = canvasRef.current.clientWidth;
    const imageCanvasHeight = imageCanvasRatio * imageCanvasWidth;

    const text = note || "";
    ctx.font = "10px Arial";
    const lines = convertToLines(ctx, text, imageCanvasWidth);
    const noteHeight = lines.length ? lines.length * 10 + 10 : 0;
    canvasRef.current.style.height = `${imageCanvasHeight + noteHeight}px`;

    canvasRef.current.width = imageCanvasWidth;
    canvasRef.current.height = imageCanvasHeight + noteHeight;

    ctx.clearRect(0, 0, imageCanvasWidth, imageCanvasHeight + noteHeight);

    // draw background
    ctx.fillStyle = backgroundColor;
    if (noteHeight) {
      ctx.fillRect(0, imageCanvasHeight, imageCanvasWidth, noteHeight);
    }

    //draw image
    if (imageRef.current?.width) {
      ctx.drawImage(
        imageRef.current,
        0,
        0,
        imageCanvasWidth,
        imageCanvasHeight
      );
    }

    // draw text
    const backgroundColorRGB = hexRgb(backgroundColor);
    const isBackgroundColorLight = colourIsLight(
      backgroundColorRGB.red,
      backgroundColorRGB.green,
      backgroundColorRGB.blue
    );

    ctx.fillStyle = isBackgroundColorLight ? "black" : "white";
    ctx.font = "10px Arial";
    ctx.textAlign = "left";
    for (var l = 0; l < lines.length; l++) {
      ctx.fillText(
        lines[l],
        0, //imageCanvasWidth / 2,
        imageCanvasHeight + (l + 1) * 10
      );
    }

    // reload if image aspect ratio is invalid
    setDummy(dummy + 1);
  };

  const handleFileSelected = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      imageRef.current.src = URL.createObjectURL(file);
      setFile(file);
    }
  };

  const onCreateClicked = async (promocion) => {
    try {
      setIsWorking(true);
      await apiServerClient.customer.add.create(
        note,
        areaIds,
        file,
        categoryId,
        backgroundColor,
        promocion._id
      );
      if (promocion.price > 0) {
        showSuccess(
          "Anuncio creado. Recibirás información de como pagar el anuncio a tu correo",
          5000
        );
      } else {
        showSuccess("Anuncio creado");
      }
      navigate("./..");
    } catch (ex) {
      handleException(ex);
      setIsWorking(false);
    }
  };

  // const getSelectValues = (select) => {
  //   var result = [];
  //   var options = select && select.options;
  //   var opt;

  //   for (var i = 0, iLen = options.length; i < iLen; i++) {
  //     opt = options[i];

  //     if (opt.selected) {
  //       result.push(opt.value || opt.text);
  //     }
  //   }
  //   return result;
  // };

  // const setAreaIds2 = (target) => {
  //   setAreaIds(getSelectValues(target));
  // };

  const handleAreaToggled = (e, area) => {
    if (areaIds.includes(area._id)) {
      setAreaIds(areaIds.filter((id) => id != area._id));
    } else {
      setAreaIds([...areaIds, area._id]);
    }
  };

  const steps = [
    {
      name: "Image",
      isFormOk: () => {
        if (!file && !addId) return false;
        if (imageRef.current.height) {
          const aspectRatio = imageRef.current.height / imageRef.current.width;
          if (aspectRatio > 2.5) return false;
        }
        return true;
      },
      gotoNext: async () => {
        setStepIndex(stepIndex + 1);
      },
      hasPrevious: () => false,
      hasNext: () => true,
      hasUpdate: () => false,
      hasCreate: () => false,
      render: () => {
        return (
          <div className="fileUpload">
            <label className="form-label">
              Ok, empezamos! Cual foto quieres para tu anuncio:
            </label>
            <div>
              <label htmlFor="addFile" style={{ cursor: "pointer" }}>
                <img src={select} alt="" className="btnImg80" />
              </label>
            </div>
            <input
              id="addFile"
              className="form-control"
              type="file"
              // accept=".jpg,.png"
              accept="image/png, image/jpeg"
              onChange={handleFileSelected}
            />
          </div>
        );
      },
    },
    {
      name: "Note",
      isFormOk: () => true,
      gotoNext: async () => {
        if (note) setStepIndex(stepIndex + 1);
        else setStepIndex(stepIndex + 2);
      },
      gotoPrevious: async () => {
        setStepIndex(stepIndex - 1);
      },
      hasPrevious: () => true,
      hasNext: () => true,
      hasUpdate: () => false,
      hasCreate: () => false,
      render: () => {
        return (
          <div>
            <label className="form-label">Quieres agregar una nota:</label>
            <textarea
              className="form-control"
              rows={3}
              value={note}
              onChange={(e) => {
                let note = e.target.value;

                note = parseNote(note);

                setNote(note);
                updatePreview(note, backgroundColor);
              }}
            ></textarea>
            <div style={{ textAlign: "right" }}>{note.trim().length} / 250</div>
            <div>
              Tip: Puedes decir que entregas en alguna zona especial o mencionar
              alguna promoción
            </div>
          </div>
        );
      },
    },
    {
      name: "Note background",
      isFormOk: () => !!backgroundColor,
      gotoNext: async () => {
        setStepIndex(stepIndex + 1);
      },
      gotoPrevious: async () => {
        setStepIndex(stepIndex - 1);
      },
      hasPrevious: () => true,
      hasNext: () => true,
      hasUpdate: () => false,
      hasCreate: () => false,
      render: () => {
        return (
          <div>
            <label className="form-label">
              Quieres elegir un color de fondo a tu nota:
            </label>
            <HexColorPicker
              color={backgroundColor}
              onChange={(color) => {
                const backgroundColor = color;
                setBackgroundColor(backgroundColor);
                updatePreview(note, backgroundColor);
              }}
            />
            <label>Tip: Elige un color que encaje con tu anuncio</label>
          </div>
        );
      },
    },
    {
      name: "Category",
      isFormOk: () => !!categoryId,
      gotoNext: async () => {
        setStepIndex(stepIndex + 1);
      },
      gotoPrevious: async () => {
        if (note) setStepIndex(stepIndex - 1);
        else setStepIndex(stepIndex - 2);
      },
      hasPrevious: () => true,
      hasNext: () => true,
      hasUpdate: () => false,
      hasCreate: () => false,
      render: () => {
        return (
          <div>
            <label className="form-label">
              Elige una categoría de búsqueda:
            </label>
            <div>
              <select
                className="form-control"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
              >
                {categories.map((c) => (
                  <option key={c._id} value={c._id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      },
    },
    {
      name: "Area",
      isFormOk: () => areaIds.length > 0,
      gotoNext: async () => {
        setStepIndex(stepIndex + 1);
      },
      gotoPrevious: async () => {
        setStepIndex(stepIndex - 1);
      },
      hasPrevious: () => true,
      hasNext: () => !addId,
      hasUpdate: () => !!addId,
      hasCreate: () => false,
      render: () => {
        return (
          <div>
            <label className="form-label">
              En cuales zonas te quieres publicar:
            </label>
            <div>
              {areas.map((a) => (
                <div key={a._id}>
                  <input
                    type="checkbox"
                    checked={areaIds.includes(a._id)}
                    onChange={(e) => handleAreaToggled(e, a)}
                  />{" "}
                  {a.name}
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      name: "Elije tiempo de publicacion",
      isFormOk: () => true,
      gotoPrevious: async () => {
        setStepIndex(stepIndex - 1);
      },
      hasPrevious: () => true,
      hasNext: () => false,
      hasUpdate: () => false,
      hasCreate: () => true,
      render: () => {
        return (
          <div>
            <label className="form-label">Elije tiempo de publicación:</label>
            {promotions.map((p, index) => {
              return (
                <div
                  key={p._id}
                  className={`row ${index > 0 ? "mt-4" : ""}`}
                  style={{
                    borderRadius: 5,
                    border: "1px solid #ced4da",
                  }}
                >
                  <div
                    className="col-6"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>
                      {p.description}
                    </div>
                    <div style={{ fontSize: 26, fontWeight: "bold" }}>
                      ${p.price} pesos
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      className="mybtn btn"
                      onClick={() => onCreateClicked(p)}
                      disabled={isWorking}
                      style={{ marginTop: 30 }}
                    >
                      {p.price > 0 ? (
                        <img
                          src={paymentInstructions}
                          alt=""
                          className="img-fluid btnImg80"
                        />
                      ) : (
                        <img
                          src={comprar}
                          alt=""
                          className="img-fluid btnImg80"
                        />
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const step = steps[stepIndex];

  return (
    <div className="container">
      <img
        src={mandameinbox}
        alt=""
        className="img-fluid"
        style={{ marginBottom: 30, maxHeight: 150 }}
      />
      <div>
        <div className="row">
          <div className="col-sm">
            {step.render()}
            <div>
              {step.hasPrevious() && (
                <button
                  onClick={step.gotoPrevious}
                  className="mybtn btn"
                  disabled={isWorking}
                  style={{ marginTop: 30, maxWidth: "50%" }}
                >
                  <img src={prev} alt="" className="img-fluid btnImg80" />
                </button>
              )}
              {step.hasNext() && (
                <button
                  onClick={step.gotoNext}
                  disabled={!step.isFormOk() || isWorking}
                  className="mybtn btn"
                  style={{ marginTop: 30, maxWidth: "50%" }}
                >
                  <img src={next} alt="" className="img-fluid btnImg80" />
                </button>
              )}
              {step.hasUpdate() && (
                <button
                  className="mybtn btn"
                  onClick={onUpdateClicked}
                  disabled={!step.isFormOk() || isWorking}
                  style={{ marginTop: 30 }}
                >
                  <img src={actualizar} alt="" className="img-fluid btnImg80" />
                </button>
              )}
            </div>
          </div>

          <div className="col-sm">
            <div>
              <label className="form-label">Previsualizar:</label>
              <div className="col">
                <canvas
                  width={300}
                  height={300}
                  ref={canvasRef}
                  style={{
                    width: "100%",
                    height: 300,
                    border: "1px solid #ced4da",
                    borderRadius: 5,
                  }}
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
