import * as React from "react";
import { useMessageContext } from "./message.context";

const exceptionContext = React.createContext();

export const useExceptionContext = () => {
  return React.useContext(exceptionContext);
};

export const ExceptionContext = ({ children }) => {
  const { showError } = useMessageContext();

  const handleException = (ex) => {
    if (ex instanceof Error) {
      ex = ex.message;
    }

    if (ex instanceof Object) {
      ex = JSON.stringify(ex);
    } else if (typeof ex === "string") {
    } else {
      ex = ex.toString();
    }

    showError(ex);
  };

  const value = { handleException };

  return (
    <exceptionContext.Provider value={value}>
      {children}
    </exceptionContext.Provider>
  );
};
