import { useState } from "react";

import logo from "../../../../images/Logo Mandame Inbox 01.PNG";
import enviar from "../../../../images/Enviar.PNG";

import validateUtil from "../../../../utils/validate.util";
import { useMessageContext } from "../../../contexts/message.context";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";
import { Link } from "react-router-dom";

const Page = ({}) => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [email, setEmail] = useState("");

  const handleSendClicked = async () => {
    try {
      await apiServerClient.user.sendLostPassword(email);
      showSuccess("Correo enviado");
    } catch (ex) {
      handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!email) return false;
    if (!validateUtil.isEmail(email)) return false;

    return true;
  };

  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <div className="row">
        <img src={logo} alt="" />
      </div>
      <div className="row">
        <div className="header1">Olvidaste tu contraseña</div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          E-mail:
        </div>
        <div>
          <input
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            marginBottom: 50,
          }}
        >
          <button
            onClick={handleSendClicked}
            className="mybtn btn"
            disabled={!isFormOk()}
          >
            <img
              src={enviar}
              alt=""
              className="btnImg80"
              style={{ marginTop: "30px" }}
            />
          </button>
          <div
            style={{
              color: "#ff5f29",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Escribe el email que registraste y se enviará un mail a tu correo.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
