import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [promotions, setPromotions] = useState([]);
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const promotions = await apiServerClient.admin.promotion.getAll();
      setPromotions(promotions);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const onPromotionClicked = (promotion) => {
    navigate(`./${promotion._id}`);
  };

  return (
    <div className="container">
      <div className="header1">PROMOCIONES</div>
      <div>
        <Link to={`./nuevo`} className="btn btn-primary">
          Nuevo
        </Link>
      </div>
      <div>
        <table className="table table-striped table-clickable">
          <thead>
            <tr>
              <th>Descripción</th>
              <th>Inicio</th>
              <th>Termina</th>
              <th>Precio</th>
              <th>Meses</th>
              <th>Max uso</th>
              <th>Oculto</th>
            </tr>
          </thead>
          <tbody>
            {promotions.map((p) => {
              return (
                <tr key={p._id} onClick={() => onPromotionClicked(p)}>
                  <td>
                    {p.description} {p.default ? "(principal)" : ""}
                  </td>
                  <td>{p.startDate}</td>
                  <td>{p.endDate}</td>
                  <td>{p.price}</td>
                  <td>{p.months}</td>
                  <td>
                    {p.maxPromotionsPerAdd} / {p.maxPromotionsPerUser}
                  </td>
                  <td>{p.hidden ? "Si" : "No"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Page;
