import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { MessageContext } from "./components/contexts/message.context";
import { ExceptionContext } from "./components/contexts/exception.context";

import "./App.css";
import AppNav from "./components/pages/app/nav";
import TestScreen from "./components/pages/test/test.screen";
import { UserContext } from "./components/contexts/user.context";

function App() {
  //return <TestScreen></TestScreen>;

  return (
    <MessageContext>
      <UserContext>
        <BrowserRouter>
          <ExceptionContext>
            <AppNav />
          </ExceptionContext>
        </BrowserRouter>
      </UserContext>
    </MessageContext>
  );
}

export default App;
