import { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../../images/Logo Mandame Inbox 01.PNG";
import registrar from "../../../images/Registrar.PNG";

import validateUtil from "../../../utils/validate.util";
import { useMessageContext } from "../../contexts/message.context";
import { useExceptionContext } from "../../contexts/exception.context";
import { useUserContext } from "../../contexts/user.context";

const Page = ({}) => {
  const { showSuccess } = useMessageContext();
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const handleSignupClicked = async () => {
    try {
      setIsWorking(true);
      await apiServerClient.user.signup(name, email, password);

      showSuccess(
        "Checa tu correo (También en los no deseados) y sigue los instrucciones para verificar tu cuenta!",
        false
      );
      setName("");
      setEmail("");
      setPassword("");
    } catch (ex) {
      handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!password) return false;
    if (password.length <= 3) return false;
    if (!email) return false;
    if (!name) return false;
    if (!validateUtil.isEmail(email)) return false;

    return true;
  };

  const handleKeydown = async (e) => {
    if (e.which === 13) {
      e.preventDefault();

      if (!isFormOk()) return;

      await handleSignupClicked();
    }
  };

  return (
    <div className="container" style={{ maxWidth: 600 }}>
      <div className="row">
        <img src={logo} alt="" />
      </div>
      <div className="row">
        <div className="header1">Datos para registrarte</div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          Nombre:
        </div>
        <div>
          <input
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          E-mail:
        </div>
        <div>
          <input
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          Contraseña:
        </div>
        <div>
          <input
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeydown}
          />
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: 50,
          }}
        >
          <button
            onClick={handleSignupClicked}
            className="mybtn btn"
            disabled={!isFormOk()}
          >
            <img
              src={registrar}
              alt=""
              className="btnImg80"
              style={{ marginTop: "30px" }}
            />
          </button>
          <div>
            <Link to="../ingresar" className="link1">
              Tengo cuenta, dejame ingresar
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
