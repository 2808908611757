import { useState, useEffect } from "react";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";
import { useMessageContext } from "../../../contexts/message.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [files, setFiles] = useState([]);
  const { showSuccess } = useMessageContext();

  const refresh = async () => {
    try {
      const files = await apiServerClient.admin.files.getAll();
      setFiles(files);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleDeleteFileClicked = async (file) => {
    try {
      await apiServerClient.admin.files.delete(file.name);

      setFiles(files.filter((f) => f !== file));
    } catch (ex) {
      handleException(ex);
    }
  };
  const handleDeleteAllClicked = async () => {
    try {
      await apiServerClient.admin.files.deleteAll();

      setFiles([]);
    } catch (ex) {
      handleException(ex);
    }
  };
  const handleCopyFileUrlToClipboardClicked = (file) => {
    navigator.clipboard.writeText(window.location.origin + file.url);
    showSuccess("Enlace copiado al portapapeles");
  };
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragEnter = (e) => {
    preventDefaults(e);
    e.target.classList.add("highlight");
  };
  const handleDragOver = (e) => {
    preventDefaults(e);
  };
  const handleDragLeave = (e) => {
    preventDefaults(e);
    e.target.classList.remove("highlight");
  };

  const handleFiles = async (files2) => {
    for (var file of files2) {
      await apiServerClient.admin.files.insert(file);
    }
  };

  const handleDrop = async (e) => {
    preventDefaults(e);
    const dt = e.dataTransfer;
    const files = dt.files;

    await handleFiles(files);
    e.target.classList.remove("highlight");
    await refresh();
  };

  return (
    <div className="container">
      <div className="header1">Archivos</div>
      <div>
        <button className="btn btn-danger" onClick={handleDeleteAllClicked}>
          Borrar todos
        </button>
      </div>

      <div>
        <table className="table table-striped ">
          <thead>
            <tr>
              <th>Nombre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map((f) => {
              return (
                <tr key={f._id}>
                  <td>{f.name}</td>
                  <td className="text-end">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm me-2"
                      onClick={() => handleCopyFileUrlToClipboardClicked(f)}
                    >
                      Copiar enlace
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      onClick={() => handleDeleteFileClicked(f)}
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className="d-flex justify-content-center align-items-center"
        style={{ border: "2px solid #ff5f29", height: 100, borderRadius: 8 }}
      >
        Subir archivos rastreandolos aqui
      </div>
    </div>
  );
};

export default Page;
