class ApiserverAdminClient {
  #key = "trgki-65ji0yhnovy-iy5-ok6T^%&@fk7!%Up";

  #handleResponse = async (response) => {
    if (response.ok) {
      const response2 = await response.json();
      return response2;
    } else if (response.status === 500) {
      const response2 = await response.json();
      throw new Error(response2.error);
    } else {
      const response2 = await response.statusText;
      throw new Error(response2);
    }
  };

  #post = async (url, authenticate, data) => {
    console.log(url);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (authenticate) {
      options.headers.Authorization = "Key " + this.#key;
    }

    if (data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(`/api/v1${url}`, options);

    const result = await this.#handleResponse(response);
    console.log("result", result);

    return result;
  };

  util = {
    updateMany: async (entity, filter, update) => {
      await this.#post("/util/updateMany", true, { entity, filter, update });
    },
    deleteMany: async (entity, filter) => {
      await this.#post("/util/deleteMany", true, { entity, filter });
    },
    find: async (entity, filter) => {
      const data = await this.#post("/util/find", true, { entity, filter });

      return data;
    },
  };
}

export default ApiserverAdminClient;
