import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useExceptionContext } from "../../../contexts/exception.context";
import { useUserContext } from "../../../contexts/user.context";

const Page = () => {
  const { handleException } = useExceptionContext();
  const { apiServerClient } = useUserContext();
  const [areas, setAreas] = useState([]);
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const areas = await apiServerClient.admin.area.getAll();
      setAreas(areas);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const onAreaClicked = (area) => {
    navigate(`./${area._id}`);
  };

  return (
    <div className="container">
      <div className="header1">Areas</div>
      <div>
        <table className="table table-striped table-clickable">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Boton</th>
              <th>Portada</th>
            </tr>
          </thead>
          <tbody>
            {areas.map((a) => {
              return (
                <tr key={a._id} onClick={() => onAreaClicked(a)}>
                  <td>{a.name}</td>
                  <td>
                    <img
                      src={a.buttonFile?.url}
                      alt=""
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    />
                  </td>
                  <td>
                    <img
                      src={a.coverFile?.url}
                      alt=""
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Page;
