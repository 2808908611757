import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const messageContext = React.createContext();

export const useMessageContext = () => {
  return React.useContext(messageContext);
};

export const MessageContext = ({ children }) => {
  const showError = (message) => {
    toast(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      type: "error",
    });
  };

  const showSuccess = (message, autoClose = 2000) => {
    toast(message, {
      position: "bottom-right",
      autoClose,
      hideProgressBar: true,
      type: "success",
    });
  };

  const value = { showError, showSuccess };

  return (
    <messageContext.Provider value={value}>
      {children}
      <ToastContainer theme="colored" />
    </messageContext.Provider>
  );
};
