import { Link, useNavigate } from "react-router-dom";

import logo from "../../../images/Logo Mandame Inbox 01.PNG";
import verRevista from "../../../images/Ver Revista_2.png";
import anunciateYa from "../../../images/Anunciate YA OK.PNG";
import seperator from "../../../images/seperator.png";
import promo from "../../../images/promo2.PNG";
import comprarInternet from "../../../images/Comprar Internet.jpg";
import facebook from "../../../images/Facebook.PNG";
import whatsapp from "../../../images/Whatsapp.PNG";
import click from "../../../images/click.PNG";
import logo2 from "../../../images/Logo Mandame Inbox 03.PNG";
import registrame from "../../../images/registrame.png";
import apoyodiseno from "../../../images/apoyo-diseno.png";
import micuenta from "../../../images/mi-cuenta.png";

import settings from "../../../utils/settings";
import { useUserContext } from "../../contexts/user.context";

const MainPage = () => {
  const navigate = useNavigate();
  const { signOut } = useUserContext();

  const handleSignupClicked = () => {
    signOut();
    navigate("/miembros/usuario/registrarme");
  };

  const handleSigninClicked = () => {
    signOut();
    navigate("/miembros/usuario/ingresar");
  };

  const handleDesignClicked = () => {
    navigate("diseno");
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <div className="row">
            <img src={logo} className="img-fluid" alt="logo" />
          </div>
        </div>
        <div className="col-sm" style={{ textAlign: "center", marginTop: 20 }}>
          <Link to="/revistas" className="mybtn btn">
            <img src={verRevista} className="img-fluid btnImg80" alt="logo" />
          </Link>
        </div>
      </div>
      <div className="row">
        <img src={comprarInternet} alt="" />
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        <p>
          ¡Bienvenido a Mándame Inbox! Somos una revista comercial en donde
          encontrarás un espacio de compra y venta de productos o servicios de
          tu interés.
        </p>
        <p>
          Nuestra misión es que puedas encontrar de manera fácil el producto o
          servicio que estás buscando y si lo que deseas es vender que está
          herramienta sea de ayuda para ser ese contacto entre tu y tus futuros
          clientes.
        </p>
        <p>
          Estamos felices de compartir este camino contigo, gracias por
          emprender, te deseamos mucho éxito y crecimiento personal.
        </p>{" "}
      </div>
      <div className="row">
        <div className="col-sm text-center">
          <div
            className="textMedium textBlack"
            style={{ fontSize: 35, marginTop: 30, lineHeight: "40px" }}
          >
            Anúnciate por sólo
          </div>
          <div
            className="textBold textOrange"
            style={{ fontSize: 70, lineHeight: "70px" }}
          >
            $ 250
            <span style={{ fontSize: 40, marginLeft: 10 }}>pesos</span>
          </div>
          <div
            className="textMedium textBlack"
            style={{ fontSize: 50, lineHeight: "40px" }}
          >
            mensuales
          </div>
          <div>
            <Link to="/miembros/usuario/registrarme" className="mybtn btn">
              <img
                src={anunciateYa}
                className="img-fluid btnImg80"
                alt=""
                style={{ marginTop: 40 }}
              />
            </Link>
          </div>
        </div>
        <div className="col-sm text-center">
          <Link to="/promos">
            <img
              src={click}
              className="img-fluid"
              alt="logo"
              style={{ maxHeight: 400 }}
            />
          </Link>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <img
          src={logo2}
          className="img-fluid"
          alt=""
          style={{ maxHeight: 80, marginTop: 40, marginBottom: 40 }}
        />
      </div>

      <div
        className="row"
        style={{
          marginBottom: 50,
        }}
      >
        <div className="col-sm" style={{ textAlign: "center" }}>
          <button onClick={handleSignupClicked} className="mybtn btn">
            <img src={registrame} alt="" />
            <div style={{ fontSize: 20, fontWeight: "bold" }}>Registrarme</div>
            <div style={{ maxWidth: 250 }}>
              Ingresa tu email y elige una contraseña que usarás aqui.
            </div>
          </button>
        </div>
        <div className="col-sm" style={{ textAlign: "center" }}>
          <button onClick={handleSigninClicked} className="mybtn btn">
            <img src={micuenta} alt="" />
            <div style={{ fontSize: 20, fontWeight: "bold" }}>Soy Miembro</div>
            <div style={{ maxWidth: 250 }}>
              Ya estoy registrado, quiero ver o modificar mi anuncio.
            </div>
          </button>
        </div>
        <div className="col-sm" style={{ textAlign: "center" }}>
          <button onClick={handleDesignClicked} className="mybtn btn">
            <img src={apoyodiseno} alt="" />
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              Apoyo en Diseño
            </div>
            <div style={{ maxWidth: 250 }}>
              No tengo diseño para mi anuncio y quiero solicitar que me hagan
              uno.
            </div>
          </button>
        </div>
      </div>
      <div className="row">
        <div style={{ textAlign: "center" }} className="mainPageText">
          {/* <div className="textBold textOrange">Pasos para Promoción:</div>

          <div className="textLight textBlack">Registrate en:</div>
          <div className="textBold textBlack hyphenate">
            {settings.company.www}
          </div> */}
          {/* 
          <div>
            <img src={seperator} alt="" style={{ width: 40 }} />
          </div> */}

          {/* <div>
            <span className="textBold textBlack">Sube el código</span> para
            hacer
          </div>
          <div>
            válida la promoción
            <span className="textBold textBlack"> y ¡Listo!</span>
          </div> */}

          {/* <div>
            <img src={seperator} alt="" style={{ width: 40 }} />
          </div>
          <div
            className="textBold textOrange"
            style={{
              marginTop: 20,
            }}
          >
            ¡Apúrate!
          </div>

          <div className="textBold textBlack">
            Todos los anuncios se publicarán el 1 de Marzo del 2023
          </div> */}

          <div style={{ textAlign: "center", marginTop: 40 }}>
            <a href={settings.company.facebook} target="_blank">
              <img src={facebook} className="img-fluid btnImg80" alt="" />
            </a>
            <a href={settings.company.whatsapp}>
              <img src={whatsapp} className="img-fluid btnImg80" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
