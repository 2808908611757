const util = {
  getStatus: (add) => {
    const { status } = add;
    const strings = [];

    if (status.isPublished) strings.push("Publicado");
    if (status.isExpired) strings.push("Vencido");
    if (status.isRejected) strings.push("Rechezado");
    if (status.isPaymentPending) strings.push("Pago pendiente");
    if (status.isReviewPending) strings.push("Autorización pendiente");

    return strings.join(", ");
  },
  getStatusHelp: (add) => {
    const { status } = add;
    const strings = [];

    if (status.isReviewPending)
      strings.push(
        "En estos momentos tu anuncio está siendo revisado para que no contenga imágenes o textos inapropiados y en breve se publicará en www.mandameinbox.com"
      );
    if (status.isPaymentPending)
      strings.push(
        "La boleta de pago ha sido enviada a tu correo. Favor de realizar tu pago."
      );

    return strings;
  },
};

export default util;
